import { useEffect, useState } from "react";
//import { useAuthContext } from './AuthContext';
import { getFromLocalStorage } from './EncryptDecrypt';

const useFetch = (url) => {
    //const auth = useAuthContext();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(false);

    const getHeaders = async () => {
        let headers = {};
        //if (auth.authToken !== null) {
            const tokenData = await getFromLocalStorage("authToken");
            if (tokenData) {
                headers.Authorization = `Token ${tokenData.token}`;
            }
        //}
        return headers;
    };

    useEffect(() => {
        const abortCon = new AbortController();
        const fetchData = async () => {
            const headers = await getHeaders(); // Fetch headers asynchronously
            
            try {
                const response = await fetch(url, {
                    signal: abortCon.signal,
                    headers: headers,
                });

                if (!response.ok) {
                    //if unauthorised, delete the token that has been used to perform the request
                    if(response.status === 401){
                        localStorage.removeItem('authToken');
                    }
                    throw Error(response.statusText);
                }

                const data = await response.json();
                setData(data);
                setLoading(false);
                setError(null);
            } catch (err) {
                if (err.name === "AbortError") {
                    console.log("fetch aborted");
                    
                } else {
                    setError(err.message);
                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            abortCon.abort();
        };
    }, [url, refresh]); // Added auth.authToken to dependencies - auth.authToken

    return { data, loading, error, refresh, setRefresh, setData };
};

export default useFetch;