import React, { createContext, useState } from 'react';

// Create the context
export const LocationContext = createContext();

// Create a provider component
const LocationProvider = ({ children }) => {
  const [markerPosition, setMarkerPosition] = useState(null); // Initial marker position
  const [placeName, setPlaceName] = useState('');

  return (
    <LocationContext.Provider value={{ markerPosition, setMarkerPosition, placeName, setPlaceName }}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
