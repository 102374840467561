import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// Create a Context for the URLs
const UrlContext = createContext();

// Create a custom hook to use the UrlContext
export const useUrlContext = () => {
  return useContext(UrlContext);
};

const UrlProvider = ({ children }) => {
  const location = useLocation();

  const prevUrlRef = useRef(localStorage.getItem('lastEndpoint')); // Store previous URL in a ref
  const [currentUrl, setCurrentUrl] = useState(location.pathname);
  const [previousUrl, setPreviousUrl] = useState(prevUrlRef.current);

  useEffect(() => {
    // Log both previous and current URLs
    console.log('Previous URL:', prevUrlRef.current);
    console.log('Current URL:', location.pathname);

    // Update localStorage with the current URL as the last accessed endpoint
    localStorage.setItem('lastEndpoint', location.pathname);

    // Update the previous URL state and ref
    setPreviousUrl(prevUrlRef.current);
    setCurrentUrl(location.pathname);

    // Update the ref for the previous URL
    prevUrlRef.current = location.pathname;
  }, [location]);

  return (
    <UrlContext.Provider value={{ previousUrl, currentUrl }}>
      {children}
    </UrlContext.Provider>
  );
};

export default UrlProvider;
