import React, { useState, useEffect } from 'react';
import Header from './Header';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import notify from './Nots';
import ChangePassword from './ChangePassword';
import '../css/UserProfile.css'; // Import the CSS file

const UserProfile = () => {
    const { setLoading } = useLoadingContext();
    const { putRequest, getRequest } = useApiRequest();
    const [profile, setProfile] = useState(null);
    const [editing, setEditing] = useState(false);
    const [password, setPassword] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        dob: '',
        gender: '',
        contact: '',
        profile: null,
    });
    const [preview, setPreview] = useState(null); // State for image preview

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                const response = await getRequest(`${domain}/accounts/profile`);
                setProfile(response);
                setFormData(response);
            } catch (error) {
                console.error('Error fetching profile:', error);
            }
        };

        fetchProfile();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prev) => ({
            ...prev,
            profile: file,
        }));

        // Create a preview of the selected image
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleImageClick = () => {
        document.getElementById('profilePicInput').click();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const form = new FormData();
        for (const key in formData) {
            if (key === 'profile' && formData[key] instanceof File) {
                form.append(key, formData[key]);
            } else if (key !== 'profile') {
                form.append(key, formData[key]);
            }
        }

        try {
            const response = await putRequest(`${domain}/accounts/profile`, form);
            console.log(response);

            if (response.status === "ok") {
                notify(JSON.stringify(response.data), "info");
            } else {
                notify(JSON.stringify(response.data), "error");
            }
            setEditing(false);
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false)
        }
    };

    if (!profile) return <div className="loading">Loading...</div>;

    return (
        <div className="profile-container">
            <Header />
            <h2>User Profile</h2>
            <div className='profileData'>
                <div>
                    <div className="profile-details">
                        <div className='text-center left'>
                            <img
                                src={preview || (profile && domain + profile.profile) || '/profilepics/user.png'}
                                alt="Profile"
                                className="profile-pic"
                                onClick={handleImageClick}
                            />
                            <div className="text-center">
                                <small className='text-muted'>Click on the image to change your profile picture</small>
                            </div>
                            <input
                                type="file"
                                id="profilePicInput"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className="details">
                            <p><strong>Username:</strong> {profile && profile.username}</p>
                            <p><strong>Email:</strong> {profile && profile.email}</p>
                            <p><strong>First Name:</strong> {profile && profile.first_name}</p>
                            <p><strong>Last Name:</strong> {profile && profile.last_name}</p>
                            <p><strong>Date of Birth:</strong> {profile && profile.dob}</p>
                            <p><strong>Gender:</strong> {profile && profile.gender}</p>
                            <p><strong>Contact:</strong> {profile && profile.contact}</p>
                        </div>
                    </div>
                    <button disabled={password} onClick={() => setEditing(!editing)} className="btn btn-primary mt-4">{editing ? "Cancel Profile Edit" : "Edit Profile"}</button>
                    <button disabled={editing} onClick={() => setPassword(!password)} className="btn btn-success mt-4 ms-4">{password ? "cancel Password Change" : "Change Password"}</button>
                </div>
                {editing ?
                    <form onSubmit={handleSubmit} className="profile-form">
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email:</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                        <div className='d-flex gap-4'>
                            <div className="form-group flex-grow-1">
                                <label>First Name:</label>
                                <input
                                    type="text"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group flex-grow-1">
                                <label>Last Name:</label>
                                <input
                                    type="text"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className='d-block d-md-flex gap-4'>
                            <div className="form-group flex-grow-1">
                                <label>Date of Birth:</label>
                                <input
                                    type="date"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="form-group flex-grow-1">
                                <label>Gender:</label>
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                            <div className="form-group flex-grow-1">
                                <label>Contact:</label>
                                <input
                                    type="text"
                                    name="contact"
                                    value={formData.contact}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="button-group">
                            <button type="submit" className="btn btn-success">Update Profile</button>
                            <button type="button" className="btn d-none" onClick={() => setEditing(false)}>Cancel</button>
                        </div>
                    </form>
                    :
                    password ? <ChangePassword /> : <ProfileSecurityAdvice />
                }
            </div>
        </div>
    );
};


const ProfileSecurityAdvice = () => {
    return (
        <div className="security-advice">
            <h3>Profile Security Advice</h3>
            <p>
                Keeping your user profile private is crucial for your online safety. Here are some important tips to help you maintain your privacy and security:
            </p>
            <ul>
                <li>
                    <strong>Regularly Update Your Password:</strong> Change your password every two weeks to minimize the risk of unauthorized access. Use strong, unique passwords that include a mix of letters, numbers, and special characters.
                </li>
                <li>
                    <strong>Enable Two-Factor Authentication:</strong> Whenever possible, enable two-factor authentication (2FA) on your account. This adds an extra layer of security by requiring a second form of verification.
                </li>
                <li>
                    <strong>Be Wary of Phishing Attempts:</strong> Always be cautious of emails or messages requesting personal information. Verify the source before responding.
                </li>
            </ul>
            <p>
                By following these guidelines, you can significantly enhance the security of your user profile and protect your personal information.
            </p>
        </div>
    );
};

export default UserProfile;
