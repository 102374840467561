import React, { useState, useEffect, useRef } from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'
import "../css/TicketPurchase.css"
import { useLocation } from 'react-router-dom';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import notify from './Nots'

const TicketPurchase = () => {
    const { getRequest, postRequest } = useApiRequest()
    const { loading, setLoading } = useLoadingContext()
    const location = useLocation();
    const [show, setShow] = useState(false);
    const [showNames, setShowNames] = useState(false);
    const [sameNames, setSameNames] = useState(true);
    // Function to get query parameters
    const query = new URLSearchParams(location.search);
    const ticketId = query.get('ticket'); // Retrieve the 'ticket' parameter
    const eventSlug = query.get('event');// Retrieve the 'event' parameter

    const initial = {
        firstName: "",
        lastName: "",
        paymentMode: "",
        phoneNumber: "",
        email: "",
        sameNames: sameNames,
        eventSlug: eventSlug,
        ticketId: ticketId,
        qty: ""
    }
    const [ticketData, setTicketData] = useState(initial)
    const [individualNames, setIndividualNames] = useState(['']);


    // Function to handle changes in form inputs
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setTicketData((prevEventData) => ({
                ...prevEventData,
                [name]: checked
            }));
        } else {
            setTicketData((prevEventData) => ({
                ...prevEventData,
                [name]: value
            }));
        }
    };

    const [selectedSeats, setSelectedSeats] = useState([]);
    const [totalSeats, setTotalSeats] = useState(0); // Adjust this as needed

    const [eventDetails, setEventDetails] = useState(null)
    const [ticketDetails, setTicketDetails] = useState(null)
    const emptyArray = Array.from({ length: 1000 }, (_, index) => index);
    const [qty, setQty] = useState(1)
    const redirectUrl = useRef()
    const totalAmount = useRef()

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const truncateString = (str) => {
        return str.length > 170 ? str.substring(0, 169) + "..." : str;
    }


    const fetchEventDetails = async () => {
        const details = await getRequest(`${domain}/event-details/${eventSlug}`)
        console.log(details)
        setEventDetails(details.details)
    }

    const fetchTicketDetails = async () => {
        const details = await getRequest(`${domain}/ticket-information/${ticketId}`)
        setTicketDetails(details)
        totalAmount.current = details.ticket_price
        setTotalSeats(details.no_seats_available)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchEventDetails(), fetchTicketDetails()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        setTicketData((prevEventData) => ({ ...prevEventData, sameNames: sameNames }))
    }, [sameNames])

    const handleChange = (e) => {
        const value = e.target.value
        totalAmount.current = ticketDetails.ticket_price * value
        setQty(value)
        setTicketData((prevEventData) => ({ ...prevEventData, qty: value }))
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleModalClose = () => { setShowNames(false); setSameNames(true) }; //; setSameNames(true) 
    const handleModalShow = () => setShowNames(true);

    useEffect(() => {
        if (sameNames === false) {
            handleModalShow()
        }
    }, [sameNames])


    const handleNameChange = (index, field, value) => {
        const updatedNames = [...individualNames];
        updatedNames[index] = { ...updatedNames[index], [field]: value };
        setIndividualNames(updatedNames);
    };

    const handlePayment = async () => {
        setLoading(true)

        const formData = new FormData()
        for (const [key, value] of Object.entries(ticketData)) {
            // Append each key-value pair to the FormData object
            formData.append(key, value);
        }
        formData.append("description", eventDetails ? eventDetails.event_plot_name : "")
        formData.append("amount", totalAmount.current)
        //formData.append("qty", qty)

        // Create a names array containing full names
        const names = [];


        if (!sameNames) {
            names.push(...individualNames.map((name) => `${name.firstName} ${name.lastName}`));

            // Append names as a single key in formData
            formData.append('names', JSON.stringify(names));
        }
        //add seat numbers if any
        formData.append("seatNumbers", JSON.stringify(selectedSeats))

        const response = await postRequest(`${domain}/make-online-payment`, formData)

        if (response.redirect_url) {
            redirectUrl.current = response.redirect_url
            setLoading(false)
            handleShow()
        }
    }

    const handleSeatSelection = (seatNumber, alreadyBookedSeats) => {
        // If seat is already selected, remove it from the selectedSeats array
        if (alreadyBookedSeats.includes(seatNumber)){
            notify(`seat number ${seatNumber} was already booked, please check another one!`, 'error')
            return
        }
        if (selectedSeats.includes(seatNumber)) {
            setSelectedSeats((prev) => prev.filter(seat => seat !== seatNumber));

        } else if (selectedSeats.length >= qty) {
            // Prevent selecting more seats than the quantity of tickets
            notify(`You can only select up to ${qty} seats. Please increase the number of tickets if you want more seats.`, 'error');
            return; // Prevent further selection
        } else {
            // If seat is not selected yet, add it to the selectedSeats array
            setSelectedSeats((prev) => [...prev, seatNumber]);
        }
        /*
        if (!selectedSeats.includes(seatNumber)) {
            setSelectedSeats((prev) => [...prev, seatNumber]);
        } else {
            setSelectedSeats((prev) => prev.filter(seat => seat !== seatNumber));
        }
        */
    };

    const handleModalSave = () => {
        // Check if the number of names matches the quantity of tickets
        if (individualNames.length !== parseInt(qty)) {
            alert("Please provide names for all tickets before proceeding.");
            return; // Prevent closing the modal
        }

        // If everything is fine, close the modal and maintain that the user needs individual names for all the tickets that they are buying
        setShowNames(false)
    };



    return (
        <div>
            <Header />
            <div className="row m-auto narrow mb-2">
                <div className="col-lg-7">
                    <h1 className="fw-bold fs-5 text-dark">Purchase Ticket for {eventDetails && eventDetails.event_plot_name}</h1> {/* - {eventDetails && eventDetails.event_category.name} */}
                    <div className="border rounded border-dark-subtle p-3 mb-3">
                        <p className="card-text text-muted d-none"><Link className='text-decoration-none text-muted'>{eventDetails && truncateString(eventDetails.event_description)}</Link></p>
                        <p className="text-danger mb-1">{eventDetails && formatDate(new Date(eventDetails.start_date_event))}</p>
                        <p className="text-muted mb-1">{eventDetails && eventDetails.venue_name}</p>
                        <p className="text-muted fw-bold mb-1">Organiser: {eventDetails && eventDetails.organiser.organiser_name}</p>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text">FullName</span>
                        <input type="text" aria-label="First name" name='firstName' onChange={handleInputChange} placeholder="firstname" className="form-control" />
                        <input type="text" aria-label="Last name" name='lastName' onChange={handleInputChange} placeholder="lastname" className="form-control" />
                    </div>
                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Payment Mode</label>
                        <select className="form-select" name='paymentMode' onChange={handleInputChange} id="inputGroupSelect01">
                            <option selected disabled>Choose from available options</option>
                            <option value="MTN MOBILE MONEY">MTN MOBILE MONEY</option>
                            <option value="AIRTEL MONEY">AIRTEL MONEY</option>
                            <option value="ONLINE CARDS">ONLINE CARDS</option>
                        </select>
                    </div>
                    <div className="input-group mb-3">
                        <label className="input-group-text" for="inputGroupSelect01">Number of Tickets</label>
                        <select className="form-select" onChange={handleChange} id="inputGroupSelect01">
                            <option value="" selected hidden>Choose...</option>
                            {emptyArray.map(item =>
                                <option key={item} value={item + 1}>{item + 1}</option>
                            )}
                        </select>
                    </div>
                    {qty > 1 && (
                        <div className="mb-3">
                            <label className="form-label">Do you want to use the same names for all tickets?</label>
                            <div>
                                <input type="radio" value="yes" checked={sameNames} onChange={() => setSameNames(true)} /> Yes
                                <input type="radio" className='ms-4' value="no" checked={!sameNames} onChange={() => setSameNames(false)} /> No
                            </div>
                        </div>
                    )}

                    <div className="mb-3">
                        <label for="formFileDisabled" className="form-label">Please select seat numbers</label>
                        <input className="form-control" type="checkbox" name="seat_number" hidden />
                        <div className="horizontal text-center">
                            <input type="checkbox" name="seat_selected" hidden />
                            <div value="{{y}}" className="selected d-none" onclick="selectSeat(this)">
                                <i className="fas fa-chair fs-3"></i>
                                <p className="fw-bold fs-6">1 </p>
                            </div>
                            {totalSeats > 0 && Array.from({ length: totalSeats }, (_, index) => index).map(seatNumber => {
                                const seatBooked = JSON.parse(ticketDetails.seats_booked).includes(seatNumber + 1)
                                return (
                                    <div
                                        key={seatNumber + 1}
                                        disabled = {seatBooked}
                                        className={`seat ${selectedSeats.includes(seatNumber + 1) || seatBooked ? 'selected' : ''}`}
                                        onClick={() => handleSeatSelection(seatNumber + 1, ticketDetails.seats_booked)}
                                        style={{
                                            cursor: selectedSeats.includes(seatNumber + 1) ? 'not-allowed' : 'pointer',
                                            opacity: selectedSeats.includes(seatNumber + 1) ? 0.5 : 1
                                        }}
                                    >
                                        {/*<i className="fas fa-chair fs-3 d-none"></i>*/}
                                        <span class="material-icons">chair</span>
                                        <p className="fw-bold fs-6">{seatNumber + 1}</p>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Phone number: </span>
                        <input type="text" name='phoneNumber' onChange={handleInputChange} className="form-control" placeholder="Phone number" />
                    </div>
                    <div className="input-group mb-3">
                        <span className="input-group-text" id="basic-addon1">Email: </span>
                        <input type="email" name='email' onChange={handleInputChange} className="form-control" placeholder="Please provide a valid Email Address" />
                    </div>
                    <button type="button" onClick={handlePayment} className="btn btn-dark w-100">Make Payment</button>
                </div>
                <div className="col-lg-5 detailed d-none d-md-block">
                    <img src={eventDetails && domain + eventDetails.banner} className="img-fluid rounded" alt="img" />
                    <div className='border rounded mt-2 ps-3 pe-3'>
                        <h1 className="fs-5 fw-bold mt-3 mb-2">Order Summary</h1>
                        <p className="fw-bold text-muted">Ticket Price: Ugx <span>{ticketDetails && ticketDetails.ticket_price.toLocaleString()}</span>/=</p>
                        <p className="text-muted">Number of Tickets: <span id="no_tickets" className="fw-bold text-success">{qty}</span></p>
                        <p className="text-muted">Seats Selected: <span id="seats_selected" className="fw-bold text-success">2</span></p>
                        <p className="text-muted">Total Amount: <span id="total_amount" className="text-danger fw-bold">Ugx {totalAmount.current && totalAmount.current.toLocaleString()}/= Only</span></p>
                        <p className="text-muted">Ticket type: <span className="fw-bold">{ticketDetails && ticketDetails.ticket_type.name}</span></p>
                        <p className="text-muted">Ticket category: <span className="fw-bold">{ticketDetails && ticketDetails.ticket_category.name}</span></p>
                    </div>
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                keyboard={false}
            >
                <Modal.Header className='bg-success text-white' closeButton>
                    <Modal.Title>Complete Payment Here</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='fs-6'>Ticket Purchase for {eventDetails && eventDetails.event_plot_name} - {eventDetails && eventDetails.event_category.name}</h4>
                    <iframe src={redirectUrl.current} className='w-100' style={{ height: "50vh" }} title="Payment Form" />
                </Modal.Body>
            </Modal>

            {/* Modal for entering individual names */}
            <Modal show={showNames} onHide={handleModalClose} backdrop="static" size="lg" centered keyboard={false} aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton className='bg-success'>
                    <Modal.Title className='fs-5 fw-bold text-white'>Enter Names To Appear for the Individual Tickets</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Array.from({ length: qty }, (_, index) => (
                        <div key={index} className="mb-3">
                            <h5 className='fs-6'>Ticket {index + 1}</h5>
                            <div className="input-group">
                                <span className="input-group-text">First Name</span>
                                <input
                                    type="text"
                                    value={individualNames[index]?.firstName || ''}
                                    onChange={(e) => handleNameChange(index, 'firstName', e.target.value)}
                                    placeholder={`ticket ${index + 1} firstname ...`}
                                    className="form-control"
                                />
                                <span className="input-group-text">Last Name</span>
                                <input
                                    type="text"
                                    value={individualNames[index]?.lastName || ''}
                                    placeholder={`ticket ${index + 1} lastname ...`}
                                    onChange={(e) => handleNameChange(index, 'lastName', e.target.value)}
                                    className="form-control"
                                />
                            </div>
                        </div>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => { handleModalSave(); /* You might want to also handle further logic here */ }}>
                        Save Names
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default TicketPurchase