import React, { state, useState } from 'react'
import { motion } from 'framer-motion'
import "../css/Contact.css"
import { domain } from './Domain'
import useApiRequest from './useApiRequest'
import { useLoadingContext } from './LoadingContext'
import notify from './Nots'

const Contact = () => {
    const { getRequest, postRequest, putRequest } = useApiRequest()
    const { setLoading } = useLoadingContext()
    const initial = {
        name: '',
        address: '',
        subject: '',
        message: ''
    }
    const [data, setData] = useState(initial)
    const { name, address, subject, message } = data

    const [errors, setErrors] = useState({})

    // Validation function
    const validateForm = () => {
        let valid = true
        const newErrors = {}

        // Validate name
        if (!name.trim()) {
            newErrors.name = 'Name is required'
            valid = false
        }

        // Validate address
        if (!address.trim()) {
            newErrors.address = 'Address is required'
            valid = false
        }

        // Validate subject
        if (!subject.trim()) {
            newErrors.subject = 'Subject is required'
            valid = false
        }

        // Validate subject
        if (!message.trim()) {
            newErrors.message = 'Message is required'
            valid = false
        }

        setErrors(newErrors)
        return valid
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target
        setData(prev => ({ ...prev, [name]: value }))
    }

    const handleSubmit = async(e) => {
        e.preventDefault()
        // Validate form
        const isValid = validateForm()

        if (isValid) {
            setLoading(true)
            const formData = new FormData()
            for (const [key, value] of Object.entries(data)) {
                formData.append(key, value);
            }
            const response = await postRequest(`${domain}/contact`, formData)

            console.log(response)

            if (response.status === "ok") {
                notify(JSON.stringify(response.data), "info")
            } else {
                notify(JSON.stringify(response.data), "error")
            }
            setLoading(false)
            setData(initial) // Reset form data
        } else {
            console.log("Form has errors. Please fix them.")
        }
    }

    return (
        <div className='contact'>
            <form onSubmit={handleSubmit}>
                <h4>Contact Information</h4>
                <p>Didnt find your looking for, kindly send us a detailed message and we shall respond to you in the shortest time possible.</p>
                <div className='div' >
                    <div>
                        <label>Name</label>
                        <input type='text' value={name} onChange={handleInputChange} name='name' placeholder='name' />
                        {errors.name && <span className="error">{errors.name} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                    <div>
                        <label>Address</label>
                        <input type='text' value={address} onChange={handleInputChange} name='address' placeholder='address' />
                        {errors.address && <span className="error">{errors.address} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                    <div>
                        <label>Subject</label>
                        <input type='text' value={subject} onChange={handleInputChange} name='subject' placeholder='subject' />
                        {errors.subject && <span className="error">{errors.subject} <b className='text-danger fw-bold'>*</b></span>}
                    </div>
                </div>
                <label>Message</label>
                <textarea name='message' onChange={handleInputChange} value={message} placeholder='message ...'></textarea>
                {errors.message && <span className="error">{errors.message} <b className='text-danger fw-bold'>*</b></span>}
                <button>Send Message</button>
            </form>

            <motion.div initial={{ opacity: 0, y: -100 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 1, type: 'spring', stiffness: 200, damping: 10 }} className='col-md-6 addressDiv'>
                <div class="d-flex align-items-center gap-4">
                    <i class="fa fa-location" aria-hidden="true"></i>
                    <div className='w-100'>
                        <h5>Address Information</h5>
                        <p>Pioneer House Plot 28 (Opp. Shell Jinja Rd)</p>
                        <p>First Floor Rm 09</p>
                        <p>P.O Box 22625(U)</p>
                        <hr />
                    </div>
                </div>
                <iframe width="100%" className='bg-light' height="300" frameborder="0" style={{ border: 0 }} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=Pioneer House Plot 28 (Opp. Shell Jinja Rd)" allowfullscreen="" title='location'></iframe>
            </motion.div>
        </div>
    )
}

export default Contact