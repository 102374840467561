import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';
import { Modal, Button } from "react-bootstrap";

const HowItWorksForm = ({ onAddOrUpdate, handleInputChange, initial, formData, setFormData, editingId, setEditingId,  handleCancleEdit, postRequest, putRequest, setLoading }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.title.trim()) return;
        if (!formData.description.trim()) return;

        const formDatas = new FormData()

        // Add text fields (title, description) to FormData
        formDatas.append("title", formData.title);
        formDatas.append("description", formData.description);

        // Add files only if they are not empty strings (i.e., they are valid files)
        if (formData.cover && formData.cover instanceof File) {
            formDatas.append("cover", formData.cover);
        }

        if (formData.video && formData.video instanceof File) {
            formDatas.append("video", formData.video);
        }

        setLoading(true)
        if (editingId) {

            const response = await putRequest(`${domain}/how-it-works/${editingId}`, formDatas);
            setEditingId(null);
            onAddOrUpdate(response);
        } else {
            const response = await postRequest(`${domain}/how-it-works`, formDatas);
            onAddOrUpdate(response);
        }
        setFormData(initial);
        setLoading(false)
    };

    return (
        <form onSubmit={handleSubmit} className='mb-2'>
            <input
                type="text"
                name='title'
                value={formData.title}
                onChange={handleInputChange}
                className='w-100 mb-2 p-2 border-secondary rounded '
                placeholder="How It Works Title ..."
            />
            <textarea rows={4} placeholder="Description ..." className='w-100 p-2 border-secondary rounded ' onChange={handleInputChange} value={formData.description} name='description'></textarea>
            <div className='mb-2'>
                <div>
                    <label className='d-block'>cover image</label>
                    <input type='file' onChange={handleInputChange} className='border-0' name='cover' />
                </div>
                <div>
                    <label className='d-block'>video file</label>
                    <input type='file' onChange={handleInputChange} className='border-0' name='video' />
                </div>
            </div>
            <button type="submit" className='btn btn-sm btn-success'>{editingId ? 'Update' : 'Add'}</button>
            <button type='button' disabled={editingId ? false : true} onClick={handleCancleEdit} className='btn btn-sm btn-danger ms-2'>canel update</button>
        </form>
    );
};

const HowItWorksList = ({ howItWorks, onDelete, onEdit }) => {
    return (
        <div>
            {howItWorks.length > 0 && howItWorks.map((howIt, index) => (
                <div key={howIt.id} className='item'>
                    <p>{howIt.title}</p>
                    <p>{howIt.description}</p>
                    <video width={320} controls poster={domain + howIt.cover} src={domain + howIt.video}></video>
                    <div className='d-flex gap-2'>
                        <button onClick={() => onDelete(howIt.id)} className='btn btn-sm btn-danger'>Delete</button>
                        <button onClick={() => onEdit(howIt)} className='btn btn-sm btn-success'>Edit</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

const HowItWorksComponent = () => {
    const { setLoading } = useLoadingContext()
    const initial = {
        title: '',
        description: '',
        cover: '',
        video: '',
    }
    const [formData, setFormData] = useState(initial);
    const [modalVisible, setModalVisible] = useState(false);

    const [editingId, setEditingId] = useState(null);
    const [howItWorks, SetHowItWorks] = useState([]);
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();

    const handleInputChange = (event) => {
        const { name, value, type, files } = event.target;
        if (type === 'file') {
            // If the input type is file, update the formData with the file
            setFormData({
                ...formData,
                [name]: files[0] // For single file upload, we take the first file
            });
        } else {
            // For regular inputs, update the formData with the value
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    useEffect(() => {
        if (editingId !== null) {
            setModalVisible(true);
        }else{
            setModalVisible(false);
        }
    }, [editingId])


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch howItWorks
                await fetchHowItWorks();
            } catch (error) {
                console.error('Error fetching howItWorks:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchHowItWorks = async () => {
        const response = await getRequest(`${domain}/how-it-works`);
        SetHowItWorks(response);
    };

    const handleAddOrUpdate = (howIt) => {
        const index = howItWorks.findIndex((c) => c.id === howIt.id);
        if (index === -1) {
            SetHowItWorks([...howItWorks, howIt]);
        } else {
            const newHowItWorks = [...howItWorks];
            newHowItWorks[index] = howIt;
            SetHowItWorks(newHowItWorks);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("are your sure you want to delete this item")) {
            setLoading(true)
            const response = await deleteRequest(`${domain}/how-it-works/${id}`);
            SetHowItWorks(howItWorks.filter((howIt) => howIt.id !== id));
            setLoading(false)
        }

    };

    const handleEdit = (howIt) => {
        setEditingId(howIt.id);
        setFormData(howIt);
    };

    const handleCancleEdit = () => {
        setFormData(initial);
        setEditingId(null);
    };


    return (
        <div className='w-100'>

            <Modal show={modalVisible} onHide={handleCancleEdit} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton className='bg-success text-white'>
                    <Modal.Title className='fs-5'>{editingId ? "Edit Selected HowTo" : "Create New HowTo"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <HowItWorksForm onAddOrUpdate={handleAddOrUpdate} initial={initial} setFormData={setFormData} handleInputChange={handleInputChange} formData={formData} editingId={editingId} setEditingId={setEditingId} handleCancleEdit={handleCancleEdit} postRequest={postRequest} putRequest={putRequest} setLoading={setLoading} />
                </Modal.Body>
            </Modal>
            <HowItWorksList
                howItWorks={howItWorks}
                onDelete={handleDelete}
                onEdit={handleEdit}
            />
        </div>
    );
};

export default HowItWorksComponent;