import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';

const FeedbackList = ({ feedbacks, onDelete }) => {
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }
    return (
        <div>
            {feedbacks.length > 0 && feedbacks.map((feedback, index) => (
                <div key={feedback.id} className='item border-bottom pb-2'>
                    <p className='m-0 fw-bold border p-2 mb-2 mt-2 rounded'>Name: {feedback.name} | <span className='text-secondary'>Phone: {feedback.phone}</span> | <span className='text-secondary'>Email: {feedback.email}</span></p>
                    <p className='m-0 fw-bold text-muted'>Experience: {feedback.experience}</p>
                    <p className='m-0 fw-bold text-muted'>Reason: {feedback.reason}</p>
                    <p className='m-0 fs-6 text-muted fs-6'>Message:<br />{feedback.comment}</p>
                    <div className='d-flex gap-2'>
                        <small>{formatDate(new Date(feedback.created_at))}</small>
                        <small>{feedback.address}</small>
                    </div>
                    <div className='d-flex gap-2'>
                        <button onClick={() => onDelete(feedback.id)} className='btn btn-sm mt-2 btn-outline-danger'>Delete</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

const FeedbackComponent = () => {
    const { setLoading } = useLoadingContext()
    const [feedbacks, setFeedbacks] = useState([]);
    const { getRequest, deleteRequest } = useApiRequest();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchFeedback();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchFeedback = async () => {
        const response = await getRequest(`${domain}/feedback`);
        setFeedbacks(response);
    };

    const handleDelete = async (id) => {
        if (window.confirm("are your sure you want to delete this item")) {
            setLoading(true)
            const response = await deleteRequest(`${domain}/feedback/${id}`);
            setFeedbacks(feedbacks.filter((feedback) => feedback.id !== id));
            setLoading(false)
        }

    };

    return (
        <div className='w-100'>
            <FeedbackList
                feedbacks={feedbacks}
                onDelete={handleDelete}
            />
        </div>
    );
};

export default FeedbackComponent;