import React, { useState, useEffect, useContext } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { LocationContext } from './LocationContext';

const containerStyle = {
  width: '100%',
  height: '400px',
};

// Default center (Kampala, as a fallback)
const defaultCenter = {
  lat: 0.3476, // Latitude for Kampala
  lng: 32.5825, // Longitude for Kampala
};

const MyMapComponent = () => {
  const [center, setCenter] = useState(defaultCenter); // Initial map center
  const { markerPosition, setMarkerPosition, placeName, setPlaceName } = useContext(LocationContext); // Use context
  //const [markerPosition, setMarkerPosition] = useState(null); // No marker initially

  useEffect(() => {
    // Get the user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userLocation = { lat: latitude, lng: longitude };
          setCenter(userLocation); // Update map center to user's location
          setMarkerPosition(userLocation); // Place marker at user's location
          fetchPlaceName(userLocation);
        },
        (error) => {
          console.error('Error getting location:', error);
          // Keep the default center if geolocation fails
        }
      );
    } else {
      console.warn('Geolocation not supported by this browser.');
    }
  }, []);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });
    fetchPlaceName({ lat: lat, lng: lng });

    console.log(`Latitude: ${lat}, Longitude: ${lng}`);
  };

  const fetchPlaceName = (position) => {
    const geocoder = new window.google.maps.Geocoder();

    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setPlaceName(results[0].formatted_address); // Set the formatted address as the place name
      } else {
        console.error('Geocoder failed due to:', status);
        setPlaceName('No place found for the selected location.');
      }
    });
  };

  return (
    <div>
      <LoadScript googleMapsApiKey="AIzaSyDDq-pEmpP_jk6_vizXV1TJT0TJtAL8a3s">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={12} // Adjust zoom level
          onClick={handleMapClick}
        >
          {markerPosition && <Marker position={markerPosition} />} {/* Render marker dynamically */}
        </GoogleMap>
      </LoadScript>
      <div style={{ marginTop: '10px', fontSize: '16px' }}>
        <strong>Selected Coordinates:</strong>
        {markerPosition ? (
          <>
            <p>Latitude: {markerPosition.lat}</p>
            <p>Longitude: {markerPosition.lng}</p>
            <p><strong>Place Name:</strong> {placeName}</p>
          </>
        ) : (
          <p>Click on the map to select a location</p>
        )}
      </div>
    </div>
  );
};

export default MyMapComponent;
