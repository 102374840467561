import React, { useState, useRef, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Header from './Header'
import "../css/CreatePlot.css"
import { domain } from './Domain'
import useApiRequest from './useApiRequest'
import useFetch from './useFetch'
import { useLoadingContext } from './LoadingContext'
import notify from './Nots'
import { saveToLocalStorage, getFromLocalStorage } from './EncryptDecrypt'
import { useEventContext } from './EventContext'
import { useAuthContext } from './AuthContext'
import EventPreview from './EventPreview'
import { useUrlContext } from './RouterLogger'
import { Modal, Button, Form } from "react-bootstrap";
import { motion, AnimatePresence } from 'framer-motion'
import MyMapComponent from './MapComponent'
import LocationPicker from './MapLocationPicker'
import { LocationContext } from './LocationContext'


const Nav = ({ selected, setSelected }) => {
  return (
    <div className="mainNav container pt-3 d-flex justify-content-evenly">
      <Link onClick={() => setSelected(0)} className={selected === 0 ? "fw-bold selectors select" : "fw-bold selectors"}>1. <span>organiser profile</span></Link>
      <p><i className="fa fa-arrow-right"></i></p>
      <Link onClick={() => setSelected(1)} className={selected === 1 ? "fw-bold selectors select" : "fw-bold selectors"}>2. <span>Event details</span></Link>
      <p><i className="fa fa-arrow-right"></i></p>
      <Link onClick={() => setSelected(2)} className={selected === 2 ? "fw-bold selectors select" : "fw-bold selectors"}>3. <span>Ticket Information</span></Link>
      <p><i className="fa fa-arrow-right"></i></p>
      <Link onClick={() => setSelected(3)} className={selected === 3 ? "fw-bold selectors select" : "fw-bold selectors"}>4. <span>Event Preview</span></Link>
    </div>
  )
}

const OrganiserProfile = ({ setSelected, setLoading, event, setEvent }) => {
  const { getRequest, postRequest, putRequest } = useApiRequest()
  const initialUserData = {
    contact: "",
    email: "",
    organiser_name: "",
    facebook: "",
    twitter: "",
    website: ""
  };
  const [userData, setUserData] = useState(initialUserData)
  const [reload, setReload] = useState(false)

  // Function to reset all userData values to empty strings
  const resetUserData = () => {
    setUserData(initialUserData);
  };

  // Function to handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value
    }));
    //console.log(userData)
  };

  //oncomponent load, check local storage for presence of key
  useEffect(() => {
    const eventReg = getFromLocalStorage("eventReg")
    const fetchUserData = async () => {
      const response = await getRequest(`${domain}/organiser-profiles?slug=${eventReg}`);
      if (response) {
        setUserData((prevUserData) => ({
          ...prevUserData,
          id: response.id,
          event: response.id,
          eventName: response.event_plot_name,
          contact: response.contact,
          email: response.email,
          organiser_name: response.organiser_name,
          facebook: response.facebook,
          twitter: response.twitter,
          website: response.website
        }));
      }
    };
    if (eventReg !== null) {
      fetchUserData()
    }
  }, [reload])

  const handleProfile = async (event) => {
    setLoading(true)
    event.preventDefault();
    const formData = new FormData(event.target)
    //formData.append("organiser", 1)
    let response;
    if (userData.id) {
      response = await putRequest(`${domain}/organiser-profiles/${userData.id}`, formData)
    } else {
      response = await postRequest(`${domain}/organiser-profiles`, formData)
    }

    if (response.status === "ok") {
      //save to local storage for useage at a later point if page is refreshed!
      saveToLocalStorage("eventReg", response.data.slug);
      //save event data to context
      setEvent((prevEvent) => ({
        ...prevEvent,
        event: response.data.id,
        eventName: response.data.event_plot_name
      }));
      notify(JSON.stringify(response.data), "info")
      setSelected(1); //move to the next page
    } else {
      notify(JSON.stringify(response.data), "error")
    }
    event.target.reset()
    resetUserData()
    setLoading(false)
    setReload(!reload)
  };

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  // Function to check if any input is filled
  // Function to check if any input is filled
  const isFormFilled = Object.values(userData).some(value => value !== "" && value !== null);


  return (
    <div className='min animate__animated animate__fadeIn'>
      <h6 className='d-block d-md-none'>Organiser Profile</h6>
      <form className="container border border-bottom-0 border-end-0 border-start-0  border-warning rounded bg-light p-4" onSubmit={handleProfile}>
        <div className="mb-3">
          <label className="form-label text-primary">Contact Information</label>
          <div className="row">
            <div className="col-lg-6">
              <input type="tel" name="contact" required value={userData.contact} onChange={handleInputChange} placeholder="contact line one" className="form-control mb-1 mb-lg-0 mb-sm-2" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div className="col-lg-6">
              <input type="email" name="email" required value={userData.email} onChange={handleInputChange} placeholder="email address" className="form-control mb-1 mb-lg-0 mb-sm-2" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
          </div>
          <div id="emailHelp" className="form-text">These contact numbers enable us contact you whenever need be so please to ensure to supply valid numbers.</div>
        </div>
        <div className="mb-3">
          <label className="form-label text-primary">Organiser Name</label>
          <input type="text" name="organiser_name" required value={userData.organiser_name} onChange={handleInputChange} className="form-control" placeholder="Organiser name" aria-label="Username" aria-describedby="addon-wrapping" />
          <div className="input-group flex-nowrap mb-3 d-none">
            <span className="input-group-text" id="addon-wrapping">Organiser Name</span>
            <input type="text" name="organiser_name" required value={userData.organiser_name} onChange={handleInputChange} className="form-control" placeholder="Organiser name" aria-label="Username" aria-describedby="addon-wrapping" />
          </div>
          <div id="emailHelp" className="form-text">This will be the organiser name displayed on your Event</div>
        </div>
        <div className="form-check form-switch mb-3">
          <input className="form-check-input" checked={checked} value={checked} onChange={handleCheckboxChange} name="online_event" type="checkbox" role="switch" />
          <label className="form-check-label text-primary">include social media urls</label>
        </div>
        <AnimatePresence>
          {checked &&
            <motion.div
              className="mb-3"
              initial={{ opacity: 0, y: -20 }}  // Initial state: invisible and slightly above
              animate={{ opacity: 1, y: 0 }}    // Animate to full opacity and normal position
              exit={{ opacity: 0, y: 20 }}      // When the checkbox is unchecked, animate it out (downwards)
              transition={{ duration: 0.5 }}     // Smooth transition duration for entire form
            >
              <label className="form-label text-primary">Social Media Urls</label>

              {/* Facebook URL Input */}
              <motion.div
                className="input-group flex-nowrap mb-3"
                initial={{ opacity: 0, x: -20 }}   // Initially hidden and slightly to the left
                animate={{ opacity: 1, x: 0 }}     // Animate to full opacity and normal position
                exit={{ opacity: 0, x: -20 }}      // On exit, move back to the left while fading out
                transition={{ duration: 0.3, delay: 0.2 }} // Staggered delay for the Facebook input
              >
                <span className="input-group-text" id="addon-wrapping">Facebook Url</span>
                <input
                  type="url"
                  name="facebook"
                  value={userData.facebook}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="www.facebook.com/"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </motion.div>

              {/* Twitter URL Input */}
              <motion.div
                className="input-group flex-nowrap mb-3"
                initial={{ opacity: 0, x: -20 }}   // Initially hidden and slightly to the left
                animate={{ opacity: 1, x: 0 }}     // Animate to full opacity and normal position
                exit={{ opacity: 0, x: -20 }}      // On exit, move back to the left while fading out
                transition={{ duration: 0.3, delay: 0.4 }} // Staggered delay for the Twitter input
              >
                <span className="input-group-text" id="addon-wrapping">Twitter Url</span>
                <input
                  type="url"
                  name="twitter"
                  value={userData.twitter}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="www.twitter.com/"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </motion.div>

              {/* Website URL Input */}
              <motion.div
                className="input-group flex-nowrap mb-3"
                initial={{ opacity: 0, x: -20 }}   // Initially hidden and slightly to the left
                animate={{ opacity: 1, x: 0 }}     // Animate to full opacity and normal position
                exit={{ opacity: 0, x: -20 }}      // On exit, move back to the left while fading out
                transition={{ duration: 0.3, delay: 0.6 }} // Staggered delay for the Website input
              >
                <span className="input-group-text" id="addon-wrapping">Website Url</span>
                <input
                  type="url"
                  name="website"
                  value={userData.website}
                  onChange={handleInputChange}
                  className="form-control"
                  placeholder="www.websitename.com/"
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </motion.div>
            </motion.div>
          }
        </AnimatePresence>
        <div className="mb-3 form-check d-none">
          <input type="checkbox" className="form-check-input" id="exampleCheck1" />
          <label className="form-check-label">Check me out</label>
        </div>
        {userData.id ?
          <button type='submit' className="btn btn-primary me-2"><i class="fa-solid fa-pen-to-square"></i> Edit Profile</button>
          :
          <button type='submit' className="btn btn-primary me-2"><i class="fa-solid fa-right-to-bracket"></i> Save Profile</button>
        }

        {/* Button used to reset the form here */}
        {/*<Link href="" onClick={handleNext} className="btn btn-success me-2"><i class="fa-solid fa-forward "></i> Next</Link>*/}
        {/*isFormFilled && 
          <button type="button" onClick={resetUserData} className="btn btn-danger text-white me-2"><i className="fa-solid fa-rotate-left"></i> Clear form</button>
        */}
        {!userData.name && <button type="button" onClick={resetUserData} className="btn btn-danger d-none text-white me-2"><i class="fa-solid fa-rotate-left"></i> Clear form</button>}
      </form>
    </div>
  )
}

const EventDetail = ({ setSelected, setLoading }) => {
  const { markerPosition } = useContext(LocationContext); // Access the context
  const { postRequest, getRequest, putRequest } = useApiRequest()
  const { data, loading, refresh, setRefresh } = useFetch(`${domain}/event-categories`)
  const initialUserData = {
    event_plot_name: "",
    organiser_slug: "",
    event_category: "",
    event_description: "",
    allow_event_reviews: false,
    restriction: "",
    banner: "",
    listing_img: "",
    start_date_event: "",
    end_date_event: "",
    venue_name: "",
    online_event: false,
    online_link: "",
    venue_address: "",
    refund_policy: "",
    gps_cordinates: false,
    latitude: "",
    longitude: "",
    category: "",
    event_capacity: "",
  };
  const banner = useRef()
  const bannerImg = useRef()
  const listing = useRef()
  const listingRef = useRef()
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(false)

  const [bannerSrc, setBannerSrc] = useState({
    banner: "/images/download.png",
    listing: "/images/download.png"
  })
  const [eventData, setEventData] = useState(initialUserData)

  const AGE_RESTRICTIONS = [
    { value: '18+', label: '18+ (For mature content or legal reasons)' },
    { value: '21+', label: '21+ (For events involving alcohol)' },
    { value: '13+', label: '13+ (Suitable for teenagers)' },
    { value: 'Family Friendly', label: 'Family Friendly (Open to all ages)' },
    { value: '5-12', label: '5-12 Years (For children in this age range)' },
  ];

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setEventData((prevEventData) => ({
            ...prevEventData,
            latitude: latitude,
            longitude: longitude
          }));
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  /*
  useEffect(() => {
    eventData.gps_cordinates && getLocation()
  }, [eventData.gps_cordinates])
  */

  // Sync markerPosition with eventData.latitude and eventData.longitude
  useEffect(() => {
    if (markerPosition) {
      setEventData((prev) => ({
        ...prev,
        latitude: markerPosition.lat,
        longitude: markerPosition.lng,
      }));
    }
  }, [markerPosition]);

  //oncomponent load, check local storage for presence of key and load event details captured initially in the online system / db
  useEffect(() => {
    const eventReg = getFromLocalStorage("eventReg")
    const fetchEventData = async () => {
      const response = await getRequest(`${domain}/event-details?slug=${eventReg}`);
      if (!response['error']) {
        setEventData((prevUserData) => ({
          ...prevUserData,
          id: response.id,
          event: response.id,
          eventName: response.event_plot_name,//added these below to populate the information needed
          event_plot_name: response.event_plot_name,
          organiser_slug: response.organiser_slug,
          event_category: response.event_category,
          event_description: response.event_description,
          allow_event_reviews: response.allow_event_reviews,
          restriction: response.restriction,
          start_date_event: response.start_date_event,
          end_date_event: response.end_date_event,
          venue_name: response.venue_name,
          online_event: response.online_event,
          online_link: response.online_link,
          venue_address: response.venue_address,
          refund_policy: response.refund_policy,
          gps_cordinates: response.gps_cordinates,
          latitude: response.latitude,
          longitude: response.longitude,
          category: response.category,
          event_capacity: response.event_capacity
        }));

        // Update the preview state
        setBannerSrc((prevEventData) => ({
          ...prevEventData,
          banner: domain + response.banner,
          listing: domain + response.listing_img
        }));
      }
      else {
        setEventData((prevEventData) => ({
          ...prevEventData,
          organiser_slug: eventReg
        }));
      }
    };
    fetchEventData()
  }, [reload])

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Update the preview state
      setBannerSrc((prevEventData) => ({
        ...prevEventData,
        banner: URL.createObjectURL(file)
      }));

      // Update the actual file data state
      setEventData((prevEventData) => ({
        ...prevEventData,
        banner: file
      }));
    }
  }

  const handleImageChange2 = (e) => {
    const file = e.target.files[0];

    if (file) {
      // Update the preview state
      setBannerSrc((prevEventData) => ({
        ...prevEventData,
        listing: URL.createObjectURL(file)
      }));

      // Update the actual file data state
      setEventData((prevEventData) => ({
        ...prevEventData,
        listing_img: file
      }));
    }
  }

  // Function to reset all userData values to empty strings
  const resetEventData = () => {
    setEventData(initialUserData);
  };

  // Function to handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: checked
      }));
    } else {
      setEventData((prevEventData) => ({
        ...prevEventData,
        [name]: value
      }));
    }
    console.log(eventData)
  };

  const handleEventDetails = async (event) => {
    event.preventDefault()
    setLoading(true)
    //const formData = new FormData(event.target)
    const formData = new FormData()
    // Loop through each property in the initialUserData object
    for (const [key, value] of Object.entries(eventData)) {
      // Append each key-value pair to the FormData object
      //alert(JSON.stringify(response.event_category.id))
      if (eventData.id && key === 'event_category') {
        formData.append(key, value.id);
      } else if (key === 'latitude' || key === 'longitude') {
        // Append latitude and longitude only if the value is not empty
        if (value !== "") {
          formData.append(key, value);
        } else {
          console.log(`Skipping empty ${key} value!`);
        }
      } else {
        formData.append(key, value);
      }
    }
    let response;
    if (eventData.id) {
      response = await putRequest(`${domain}/event-details/${getFromLocalStorage("eventReg")}`, formData)
    } else {
      response = await postRequest(`${domain}/event-details`, formData)
    }

    if (response.status === "ok") {
      //saveToLocalStorage("eventReg", response.data.slug);
      notify(JSON.stringify(response.data), "info")
      setSelected(2) //move to the next page
    } else {
      notify(JSON.stringify(response.data), "error")
    }
    event.target.reset()
    resetEventData()
    setLoading(false)
    setReload(!reload)
    //reset the banner src
    setBannerSrc((prevEventData) => ({
      ...prevEventData,
      banner: "/images/download.png",
      listing: "/images/download.png"
    }));
  };

  return (
    <div className='min animate__animated animate__fadeIn'>
      <h6 className='d-block d-md-none'>Event Details</h6>
      <form className="container register_two border border-bottom-0 border-end-0 border-start-0  border-warning rounded bg-light p-4" encType="multipart/form-data" onSubmit={handleEventDetails}>
        <div className="row mb-3">
          <div className="col-lg-6">
            <label className="form-label text-primary">Event Category ?</label>
            <select name="event_category" value={eventData.event_category} onChange={handleInputChange} className="form-select">
              <option value="" disabled>Select the event category</option>
              {data && data.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
            <div id="ticketType" className="form-text">Select category for this event.</div>
          </div>
          <div className="col-lg-6">
            <label className="form-label text-primary">Event Name</label>
            <input type="text" name="event_plot_name" value={eventData.event_plot_name} onChange={handleInputChange} placeholder="Enter the event name here" className="form-control mb-1 mb-lg-0 mb-sm-2" />
            <div className="form-text">Event name displayed to the public</div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label text-primary">Event Description</label>
          <textarea name="event_description" rows="5" value={eventData.event_description} onChange={handleInputChange} placeholder="Enter the event description here" className="form-control mb-1 mb-lg-0 mb-sm-2"></textarea>
          <div className="form-text">provide all the description available to the event, this eases clients work in selection of an event to attend.</div>
        </div>
        <div className="form-check form-switch">
          <input className="form-check-input" checked={eventData.allow_event_reviews} onChange={handleInputChange} type="checkbox" name="allow_event_reviews" role="switch" />
          <label className="form-check-label text-primary">Allow Event Reviews</label>
        </div>
        <div className="mb-3 mt-3">
          <label className="form-label text-primary">Age Restriction</label>
          <select name="restriction" value={eventData.restriction} onChange={handleInputChange} className="form-select">
            <option value="" disabled>Select a suitable restriction</option>
            {AGE_RESTRICTIONS.map(item =>
              <option key={item.value} value={item.value}>{item.label}</option>
            )}
          </select>

          <div className="form-text">The age restriction specifies which category of people can attend the created event.</div>
        </div>
        <div className="mb-3">
          <input type="file" ref={listing} name="listing_img" value={''/*bannerSrc.listing_img*/} onChange={handleImageChange2} id="imageInput2" accept="image/*" className="d-none form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="eventListing" />
          <input type="file" ref={banner} name="banner" value={''/*bannerSrc.banner*/} onChange={handleImageChange} id="imageInput" accept="image/*" className="form-control mb-1 mb-lg-0 mb-sm-2 d-none" aria-describedby="emailBanner" />
          <div className="row g-2 pt-2 pb-2 poster">
            <div className="col-lg-6 poster text-center">
              <label className="form-label text-primary">Cover image</label>
              <Link onClick={() => banner.current.click()}><div className="p-2"><img src={bannerSrc.banner} alt="uncropped image" ref={bannerImg} /></div></Link>
              <div id="eventListing" className="form-text">This will be the image displayed on our details page for the events.</div>
            </div>
            <div className="col-lg-6 poster text-center">
              <label className="form-label text-primary">Event listing image</label>
              <Link onClick={() => listing.current.click()}><div className="p-2"><img src={bannerSrc.listing} ref={listingRef} alt="uncropped image" /></div></Link>
              <div id="eventListing" className="form-text">This will be the image displayed on our events page for the event previews.</div>
            </div>
          </div>

        </div>
        <div className="mb-3 d-flex gap-2">
          <div className='flex-grow-1'>
            <label className="form-label text-primary">Event Start Date</label>
            <input type="date" name="start_date_event" value={eventData.start_date_event} onChange={handleInputChange} className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="eventStartDate" />
            <div className="form-text">Enter the event start date.</div>
          </div>
          <div className='flex-grow-1'>
            <label className="form-label text-primary">Event End Date</label>
            <input type="date" name="end_date_event" value={eventData.end_date_event} onChange={handleInputChange} className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="eventStartDate" />
            <div className="form-text">Enter the event end date.</div>
          </div>
        </div>

        <div className="mb-3">
          <label className="form-label text-primary">Refund Policy</label>
          <textarea name="refund_policy" value={eventData.refund_policy} onChange={handleInputChange} rows="3" placeholder="Provide all the available refund policy to the ticket." className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="ticketDescription"></textarea>
          <div id="refund_policy" className="form-text">provide all the available refund policy to the ticket.</div>
        </div>

        <div className="mb-3">
          <label className="form-label text-primary">Venue Name</label>
          <input type="text" name="venue_name" value={eventData.venue_name} onChange={handleInputChange} placeholder="e.g. Rwenzori Conference Hall" className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="eventVenue" />
          <div id="eventVenue" className="form-text">Please specify the event venue name.</div>
        </div>
        <div className="form-check form-switch mb-3">
          <input className="form-check-input" checked={eventData.online_event} value={eventData.online_event} onChange={handleInputChange} name="online_event" type="checkbox" role="switch" />
          <label className="form-check-label text-primary">Is this an online event</label>
        </div>
        {eventData.online_event &&
          <div className="mb-3" id="onlineInput">
            <div className="input-group flex-nowrap mb-3">
              <span className="input-group-text" id="addon-wrapping">Online Link Url</span>
              <input type="url" name="online_link" value={eventData.online_link} onChange={handleInputChange} className="form-control" placeholder="online link url" aria-describedby="addon-wrapping" />
            </div>
          </div>
        }
        <div className="mb-3">
          <label className="form-label text-primary">Event Address</label>
          <textarea name="venue_address" value={eventData.venue_address} onChange={handleInputChange} rows="5" placeholder="E.g. Sheraton Hotel" className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="eventAddress"></textarea>
          <div id="eventAddress" className="form-text">Please specify the event address clearly.</div>
        </div>
        <div className="form-check form-switch mb-3">
          <input className="form-check-input" checked={eventData.gps_cordinates} value={eventData.gps_cordinates} onChange={handleInputChange} name="gps_cordinates" type="checkbox" role="switch" />
          <label className="form-check-label text-primary">Would you want to pin exact event location on map?</label>
        </div>
        {eventData.gps_cordinates &&
          <div className="mb-3 mt-3" id="address">
            <label className="form-label text-primary">Event Co-ordinates</label>
            <div>
              <div className="input-group flex-nowrap mb-3">
                <span className="input-group-text" id="addon-wrapping">latitude</span>
                <input type="text" name="latitude" value={eventData.latitude} onChange={handleInputChange} className="form-control" placeholder="event latituide information" aria-describedby="addon-wrapping" />
              </div>
              <div className="input-group flex-nowrap mb-3">
                <span className="input-group-text" id="addon-wrapping">Longitude</span>
                <input type="text" name="longitude" value={eventData.longitude} onChange={handleInputChange} className="form-control" placeholder="event longitude information" aria-describedby="addon-wrapping" />
              </div>
            </div>
            <div id="eventCordinates" className="form-text">This information will be used to pin exact address on the google maps for address purposes.</div>
            <MyMapComponent />
          </div>
        }
        {/* 
        <div className="mb-3">
          <label className="form-label text-primary">under what category is your event?</label>
          <select name="event_category" onChange={handleInputChange} className="form-select">
            <option value="" disabled>Select a category</option>
            {data && data.map(item =>
              <option key={item.id} value={item.id}>{item.name}</option>
            )}
          </select>
          <div id="eventCategory" className="form-text">Clearly select the category under which this event falls.</div>
        </div>
        */}
        <div className="mb-3">
          <label className="form-label text-primary">Event Capacity</label>
          <input type="number" name="event_capacity" value={eventData.event_capacity} onChange={handleInputChange} placeholder="Enter the event capacity" className="form-control mb-1 mb-lg-0 mb-sm-2" id="exampleInputEmail1" aria-describedby="emailHelp" />
          <div id="eventCapacity" className="form-text">Please specify the number of huests your event can accomodate.</div>
        </div>
        {eventData.id ?
          <button type="submit" className="btn btn-primary me-2"><i class="fa-solid fa-pen-to-square"></i> Edit Event Information</button>
          :
          <button type="submit" className="btn btn-primary me-2"><i class="fa-solid fa-right-to-bracket"></i> Save Event Information</button>
        }

        {/* Button used to reset the form here */}
        {!eventData.id && <button type="button" onClick={resetEventData} className="btn btn-danger d-none text-white me-2"><i class="fa-solid fa-rotate-left"></i> Clear form</button>}
        {/*<Link onClick={() => setSelected(2)} className="btn btn-success"><i class="fa-solid fa-forward"></i> Next</Link>*/}
      </form>
    </div>
  )
}

const TicketInformation = ({ setSelected, setLoading }) => {
  const { getRequest, postRequest, putRequest } = useApiRequest()
  const [categories, setCategories] = useState([])
  const [currencies, setCurrencies] = useState([])
  const [types, setTypes] = useState([])
  const [ticketList, setTicketList] = useState([]) // Store list of tickets
  const emptyArray = Array.from({ length: 10 }, (_, index) => index);

  const calculateBookingFee = (price) => {
    if (price <= 50000) return 1000;
    if (price <= 100000) return 1500;
    if (price <= 250000) return 2000;
    if (price <= 500000) return 2500;
    if (price <= 1000000) return 3000;
    return 5000;
  };

  const fetchCategories = async () => {
    const response = await getRequest(`${domain}/ticket-categories`);
    setCategories(response);
  };

  const fetchCurrencies = async () => {
    const response = await getRequest(`${domain}/currencies`);
    setCurrencies(response);
  };

  const fetchTypes = async () => {
    const response = await getRequest(`${domain}/ticket-types`);
    setTypes(response);
  };

  useEffect(() => {
    const fetchData = async () => {
      //setLoading(true);
      try {
        // Perform all fetch requests in parallel and wait for all of them to complete
        await Promise.all([fetchCategories(), fetchCurrencies(), fetchTypes()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Set loading to false when all requests are completed (or error occurs)
        //setLoading(false);
      }
    };

    fetchData();
  }, []);


  const initialTicketData = {
    ticket_type: "",
    organiser_slug: "",
    event: "",
    eventName: "",
    refund_policy: "",
    ticket_category: "",
    ticketCategoryName: "",
    currency: "",
    ticket_price: "",
    transaction_fees: "",
    who_pays_fees: "",
    ticket_no_people: "",
    sales_start_date: "",
    sales_end_date: "",
    description: "",
    tickets_available: "",
    no_seats_available: "",
    display_count_tickets_available: false,
    allow_guests_book_seat_numbers: false,
    seats_available: ""
  }
  const [ticketData, setTicketData] = useState(initialTicketData)
  const [isEditing, setIsEditing] = useState(null); // Track whether editing a ticket
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [reload, setReload] = useState(false)

  useEffect(() => {
    const eventReg = getFromLocalStorage("eventReg")
    const fetchEventData = async () => {
      const response = await getRequest(`${domain}/event-details?slug=${eventReg}`);
      if (!response['error']) {
        setTicketData((prevEventData) => ({
          ...prevEventData,
          event: response.id,
          eventName: response.event_plot_name,
          organiser_slug: eventReg,
        }));
      }
    };

    const fetchTicketData = async () => {
      const response = await getRequest(`${domain}/ticket-information?slug=${eventReg}`);
      if (!response['error']) {
        const ticketsWithIDs = response.map(ticket => ({
          ...ticket,
          id: ticket.id,
          eventName: ticket.event.event_plot_name,
          ticketCategoryName: ticket.ticket_category.name,
          ticket_type: ticket.ticket_type,
          event: ticket.event.id,
          refund_policy: ticket.refund_policy,
          ticket_category: ticket.ticket_category.id,
          currency: ticket.currency,
          ticket_price: ticket.ticket_price,
          transaction_fees: ticket.transaction_fees,
          who_pays_fees: ticket.who_pays_fees,
          ticket_no_people: ticket.ticket_no_people,
          sales_start_date: ticket.sales_start_date,
          sales_end_date: ticket.sales_end_date,
          description: ticket.description,
          tickets_available: ticket.tickets_available,
          no_seats_available: ticket.no_seats_available,
          display_count_tickets_available: ticket.display_count_tickets_available,
          allow_guests_book_seat_numbers: ticket.allow_guests_book_seat_numbers,
          seats_available: ticket.seats_available
          //...response
        }));
        setTicketList(ticketsWithIDs);

        // Update ticketData with dynamic keys from response
        setTicketData(prevTicketData => ({
          ...prevTicketData,
          ...response // Spread all keys from the response
        }));

        console.log(ticketData)

      } else {
        console.error('Error fetching ticket data:', response.error);
      }

    };
    if (eventReg !== null) {
      fetchEventData()
      fetchTicketData()
    }
  }, [reload])

  // Function to handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'ticket_price') {
      const fee = calculateBookingFee(Number(value));
      setTicketData((prevEventData) => ({
        ...prevEventData,
        transaction_fees: fee
      }));
    }

    if (type === 'checkbox') {
      setTicketData((prevEventData) => ({
        ...prevEventData,
        [name]: checked
      }));
    } else {
      setTicketData((prevEventData) => ({
        ...prevEventData,
        [name]: value
      }));
    }
    console.log(ticketData)
  };

  const resetTicketData = () => {
    setTicketData(initialTicketData);
    setIsEditing(null);
    const eventReg = getFromLocalStorage("eventReg")
    const fetchEventData = async (slug) => {
      const response = await getRequest(`${domain}/event-details?slug=${eventReg}`);
      if (!response['error']) {
        setTicketData((prevEventData) => ({
          ...prevEventData,
          event: response.id,
          eventName: response.event_plot_name,
        }));
      }
    };
    fetchEventData()
  };

  const handleTicketInformation = async (event) => {
    event.preventDefault();

    //const formData = new FormData(event.target)
    const formData = new FormData()
    console.log("This is the data!")
    console.log(Object.entries(ticketData))
    // Loop through each property in the initialUserData object
    console.log("here is the data")
    for (const [key, value] of Object.entries(ticketData)) {
      // Append each key-value pair to the FormData object
      if (ticketData.id && key === "ticket_type") {
        formData.append(key, value.id);
      } else {
        formData.append(key, value);
      }
    }

    setLoading(true)

    let response;

    if (isEditing !== null) {
      // Update the existing ticket
      response = await putRequest(`${domain}/ticket-information/${ticketList[isEditing].id}`, formData);
      if (response.status === "ok") {
        setTicketList((prevList) =>
          prevList.map((ticket, index) =>
            index === isEditing ? ticketData : ticket
          )
        );
      }
    } else {
      // Add a new ticket
      response = await postRequest(`${domain}/ticket-information`, formData);
      if (response.status === "ok") {
        // Add the new ticket with ID to the ticketList
        const newTicket = { ...ticketData, id: response.data.id }; // Assuming the response contains the new ticket ID
        setTicketList([...ticketList, newTicket]);
        //setTicketList([...ticketList, ticketData]);
      }
    }

    //response = await postRequest(`${domain}/ticket-information`, formData)
    if (response.status === "ok") {
      notify(JSON.stringify(response.data), "info")
    } else {
      notify(JSON.stringify(response.data), "error")
    }
    setLoading(false)

    event.target.reset()
    resetTicketData()
    setReload(!reload)
  };

  const handleEditTicket = (index) => {
    // Load the selected ticket data into the form
    setTicketData(ticketList[index]);
    setIsEditing(index);
  };

  return (
    <div className='min animate__animated animate__fadeIn'>
      <h4 className='fs-6 mt-2'>Added Tickets</h4>
      <ul className="d-flex flex-wrap gap-2 m-0 p-0 mb-3">
        {ticketList.map((ticket, index) => (
          <li
            key={index}
            className="list-group-item p-2 rounded fs-6"
            style={{
              cursor: "pointer",
              backgroundColor: hoveredIndex === index ? '#f0f0f0' : 'transparent',
              color: hoveredIndex === index ? '#007bff' : 'inherit',
              transition: 'background-color 0.3s ease, color 0.3s ease',
              borderLeft: hoveredIndex === index ? '5px solid darkcyan' : '1px solid gray',
              border: '1px solid #aaa',
            }}
            onClick={() => handleEditTicket(index)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
          >
            {ticket.ticketCategoryName} - {ticket.ticket_price}
          </li>
        ))}
        {ticketList.length !== 0 && (
          <button
            type="button"
            className="btn btn-success me-2"
            onClick={() => setSelected(3)}
          >
            <i class="fa-solid fa-forward"></i> Next
          </button>
        )}
      </ul>
      <form className="container register_three border border-bottom-0 border-end-0 border-start-0  border-warning rounded bg-light p-4" onSubmit={handleTicketInformation}>
        <div className="row mb-3">
          <div className="col-lg-6">
            <label className="form-label text-primary">Ticket Type?</label>
            <select name="ticket_type" value={ticketData.ticket_type} onChange={handleInputChange} className="form-select">
              <option value="" disabled>Select a ticket type</option>
              {types.length > 0 && types.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
            <div id="ticketType" className="form-text">Select the type of ticket this is.</div>
          </div>
          <div className="col-lg-6">
            <label className="form-label text-primary">Event Attached</label>
            <div className="input-group flex-nowrap">
              <input type="text" name="eventName" readOnly value={ticketData.eventName} onChange={handleInputChange} className="form-control" placeholder="Event plot name " aria-describedby="addon-wrapping" />
            </div>
            <div id="ticketLevel" className="form-text">Event that this ticket is attached to.</div>
          </div>
          <div className="col-lg-12">
            <label className="form-label text-primary">Ticket Category</label>
            <select name="ticket_category" value={ticketData.ticket_category} onChange={handleInputChange} className="form-select">
              <option value="" disabled>Select a ticket category</option>
              {categories.length > 0 && categories.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
            <div id="ticketCategory" className="form-text">Select the Category of this ticket.</div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-6">
            <label className="form-label text-primary">Currency?</label>
            <select name="currency" value={ticketData.currency} onChange={handleInputChange} className="form-select">
              <option value="" disabled>Select a currency</option>
              {currencies.length > 0 && currencies.map(item =>
                <option key={item.id} value={item.id}>{item.name}</option>
              )}
            </select>
            <div id="currency" className="form-text">Select the Currency to use.</div>
          </div>
          <div className="col-lg-6">
            <label className="form-label text-primary">Ticket Price</label>
            <div className="input-group flex-nowrap">
              <span className="input-group-text" id="addon-wrapping">Amount</span>
              <input type="number" name="ticket_price" value={ticketData.ticket_price} onChange={handleInputChange} className="form-control" placeholder="Ticket Price " aria-describedby="addon-wrapping" />
            </div>
            <div id="ticket_price" className="form-text">Amount of the ticket in selected currency.</div>
          </div>
          <div className="col-lg-12">
            <label className="form-label text-primary">Booking Fee</label>
            <input
              type="number"
              value={ticketData.transaction_fees}
              readOnly
              className="form-control"
              aria-describedby="addon-wrapping"
            />
            <div id="bookingFee" className="form-text">Calculated based on ticket price.</div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-6">
            <label className="form-label text-primary">who pays Fees</label>
            <select name="who_pays_fees" value={ticketData.who_pays_fees} onChange={handleInputChange} className="form-select">
              <option value="" disabled>select who pays</option>
              <option value='USER'>User</option>
              <option value='ORGANISER'>Organiser</option>
            </select>
            <div id="who_pays_fees" className="form-text">Who pays Transaction fees.</div>
          </div>
          <div className="col-lg-6">
            <label className="form-label text-primary">No of people for the ticket</label>
            <select name="ticket_no_people" value={ticketData.ticket_no_people} onChange={handleInputChange} className="form-select">
              <option value="" disabled>Select the number of people</option>
              {emptyArray.map(item =>
                <option key={item} value={item + 1}>{item + 1}</option>
              )}
            </select>
            <div id="ticket_no_people" className="form-text">Number of people Ticket Permits</div>
          </div>
          <div className="col-lg-12">
            <div className="row">
              <div className="col-lg-6">
                <label className="form-label text-primary">Sales start Date</label>
                <input type="date" name="sales_start_date" value={ticketData.sales_start_date} onChange={handleInputChange} className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="startDateHelp" />
                <div id="startDateHelp" className="form-text">sales start date</div>
              </div>
              <div className="col-lg-6">
                <label className="form-label text-primary">Sales end Date</label>
                <input type="date" name="sales_end_date" value={ticketData.sales_end_date} onChange={handleInputChange} className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="startDateHelp" />
                <div id="endDateHelp" className="form-text">sales end date</div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label className="form-label text-primary">Ticket Description</label>
          <textarea name="description" value={ticketData.description} onChange={handleInputChange} rows="5" placeholder="Enter the ticket description here" className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="ticketDescription"></textarea>
          <div id="ticketDescription" className="form-text">provide all the description available to the ticket.</div>
        </div>
        <div className="row mb-3">
          <div className="col-lg-12">
            <label className="form-label text-primary">Tickets Available</label>
            <input type="number" name="tickets_available" value={ticketData.tickets_available} onChange={handleInputChange} className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="ticketsAvailable" />
            <div id="ticketAvailable" className="form-text">provide the total number of ticket available.</div>
            <div className="form-check form-switch mt-3 mb-2">
              <input className="form-check-input" type="checkbox" name="display_count_tickets_available" checked={ticketData.display_count_tickets_available} onChange={handleInputChange} role="switch" id="flexSwitchCheckDefault" />
              <label className="form-check-label text-primary">Display number of tickets available</label>
            </div>
          </div>
          <div className="col-lg-12 mb-3">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" name="allow_guests_book_seat_numbers" checked={ticketData.allow_guests_book_seat_numbers} onChange={handleInputChange} role="switch" id="flexSwitchCheckDefault" />
              <label className="form-check-label text-primary">Allow guests to book seat numbers</label>
            </div>
            <div id="ticketDescription" className="form-text">Activating this would enable guests to book seat numbers and you should provide the range of seats available for booking.</div>
          </div>
          {ticketData.allow_guests_book_seat_numbers &&
            <div className="col-lg-12">
              <label className="form-label text-primary">Seats Available</label>
              <input type="number" name="no_seats_available" value={ticketData.no_seats_available} onChange={handleInputChange} placeholder="how many seats are available" className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="seatsAvailable" />
              <div id="seatsAvailable" className="form-text">provide the number of seats available</div>
            </div>
          }
        </div>
        <div className="mb-3">
          <label className="form-label text-primary">Refund Policy</label>
          <textarea name="refund_policy" value={ticketData.refund_policy} onChange={handleInputChange} rows="3" placeholder="Provide all the available refund policy to the ticket." className="form-control mb-1 mb-lg-0 mb-sm-2" aria-describedby="ticketDescription"></textarea>
          <div id="refund_policy" className="form-text">provide all the available refund policy to the ticket.</div>
        </div>
        <button className="btn btn-primary me-2 d-none" type="submit">
          {isEditing !== null ? 'Update Ticket' : 'Save Ticket'}
        </button>
        {isEditing !== null ?
          <button className="btn btn-primary me-2" type="submit"><i class="fa-solid fa-pen-to-square"></i> Update Ticket</button>
          : <button className="btn btn-primary me-2" type="submit"><i class="fa-solid fa-right-to-bracket"></i> Create Ticket</button>
        }

        {/* Add New Ticket button */}
        {isEditing !== null && (
          <button
            type="button"
            className="btn btn-success me-2"
            onClick={() => {
              resetTicketData();
            }}
          >
            <i class="fa-solid fa-plus"></i> Add New Ticket
          </button>
        )}

        {/*ticketList.length !== 0 && (
          <button
            type="button"
            className="btn btn-success me-2"
            onClick={() => setSelected(3)}
          >
            <i class="fa-solid fa-forward"></i> Next
          </button>
        )*/}

        {/* Button used to reset the form here */}
        {!ticketData.id && <button type="button" onClick={resetTicketData} className="btn btn-danger d-none text-white me-2"><i class="fa-solid fa-rotate-left"></i> Clear form</button>}

      </form>
    </div>
  )
}

const EventPreviews = ({ setReload, reload }) => {
  const { postRequest } = useApiRequest()
  const navigate = useNavigate()
  const [regData, setRegData] = useState("")
  const [completed, setCompleted] = useState(false)
  useEffect(() => {
    const eventReg = getFromLocalStorage("eventReg")
    setRegData(eventReg)
  }, [])

  const handleApprove = async () => {
    const formData = new FormData()
    formData.append("organiser_slug", regData)
    const req = await postRequest(`${domain}/approve-event`, formData)
    if (req['status'] === 'ok') {
      setCompleted(true)
      notify(req['message'], 'info')
      if (localStorage.getItem("eventReg")) {
        localStorage.removeItem("eventReg")
      }
      if (localStorage.getItem("edit")) {
        localStorage.removeItem("edit");
      }
      //window.location.reload();
      setReload(!reload)
      navigate(`/`);
    } else {
      notify(req['error'], 'error')
    }
  }

  return (
    <p className='animate__animated animate__fadeIn'>
      {/* add promo code */}
      <EventPreview completed={completed} regData={regData} handleApprove={handleApprove} />
    </p>
  )
}


const CreatePlot = () => {
  const [reload, setReload] = useState(false)
  const { previousUrl, currentUrl } = useUrlContext();
  const { event, setEvent } = useEventContext()
  const [selected, setSelected] = useState(0)
  const { setLoading } = useLoadingContext()
  const { authToken } = useAuthContext()
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    try {
      const editing = localStorage.getItem("edit");

      // Ensure `editing` is parsed safely and compared properly
      if (editing && JSON.parse(editing) === true && previousUrl !== "/my-events") {
        setModalVisible(true); // Show the modal
      } else {
        setModalVisible(false); // Hide the modal
      }
    } catch (error) {
      console.error("Error accessing localStorage:", error);
    }
  }, [previousUrl]);

  const handleContinueEditing = () => {
    setModalVisible(false);
    // Continue with editing, any necessary state updates
    console.log("Continuing with editing...");
  };

  const handleCreateNew = () => {
    setModalVisible(false);
    // Clear the existing edit from localStorage
    localStorage.removeItem("edit");
    if (localStorage.getItem("eventReg")) {
      localStorage.removeItem("eventReg")
    }
    setReload(!reload)
    console.log("Creating a new item...");
  };

  const handleCloseModal = () => {
    setModalVisible(false); // Close the modal without making any changes
  };

  return (
    <div key={reload} className='animate__animated animate__fadeIn'>
      <Header />
      <p className='d-none'>Previous URL: {previousUrl}</p>
      <p className='d-none'>Current URL: {currentUrl}</p>
      <Modal show={modalVisible} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Do you want to continue editing or create a new item?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>You already have an item in progress. Would you like to continue editing or start a new one?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleContinueEditing}>
            Continue Editing
          </Button>
          <Button variant="danger" onClick={handleCreateNew}>
            Create New
          </Button>
        </Modal.Footer>
      </Modal>
      {authToken ?
        <>
          <Nav selected={selected} setSelected={setSelected} />
          {selected === 0 && <OrganiserProfile setSelected={setSelected} setLoading={setLoading} event={event} setEvent={setEvent} />}
          {selected === 1 && <EventDetail setSelected={setSelected} setLoading={setLoading} event={event} setEvent={setEvent} />}
          {selected === 2 && <TicketInformation setSelected={setSelected} setLoading={setLoading} event={event} setEvent={setEvent} />}
          {/*selected === 3 && <AccountDetails setSelected={setSelected} setLoading={setLoading} event={event} setEvent={setEvent} />*/}
          {selected === 3 && <EventPreviews setReload={setReload} reload={reload} />}
        </>
        :
        <div className='bg-light p-4'>
          <div className="auth-required-container">
            <div className="auth-required-image">
              {/* Add your image source here */}
              <img src="images/two.jpg" alt="Event Planner" />
              <div className='overlay'>
                <h1>This is anything that can be placed on this image</h1>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
              </div>
            </div>
            <div className="auth-required-text">
              <h1 className='text-danger'>Access Restricted</h1>
              <i class="fa-solid fa-triangle-exclamation"></i>
              <p>
                You need to be logged in to create an event or register with EventPlots.
                <br />
                Please <a href="/login">log in</a> to your account or <a href="/register">register</a> if you don't have an account.
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default CreatePlot