import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import "../css/Header.css"
import Navigator from './Navigator'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { AnimatePresence, motion } from "framer-motion"

const Header = () => {
  const { postRequest } = useApiRequest()
  const { authToken, logout } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMobile, setIsOpenMobile] = useState(false);
  const [searchMobile, setSearchMobile] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const toggleDropdownMobile = () => setIsOpenMobile(!isOpenMobile);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery) {
      // Redirect to the search results page
      navigate(`/search?q=${searchQuery}`);
      setSearchQuery("")
    }
  };

  const handleLogout = async () => {
    const response = await postRequest(`${domain}/accounts/logout`, {})
    if (response['message'] === "logout successfull") {
      logout()
      navigate("/")
    }
  }


  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setIsSticky(offset > 100);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className={`header ${isSticky ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
      <div className={`naved pe-4 ps-4 pt-2 pb-2 gap-2 ${isSticky ? 'animate__animated animate__fadeInDown sticky' : ''}`}>
        <Link className='w-auto' to={"/"}><img src='/images/logo.png' alt='logo' /></Link>
        <form onSubmit={handleSearch}>
          <input type="search" value={searchQuery} placeholder='Search Events by Event Name or Location' onChange={(e) => setSearchQuery(e.target.value)} />
          <button type="submit" className='btn btn-secondary'><i class="fa-solid fa-magnifying-glass"></i></button>
        </form>
        <div className="d-none d-md-block">
          <Link className='p-2' to={'/help-center'}>Help Center</Link>
          <Link className='p-2' to={'/how-it-works'}>How it works</Link>

          <Link className='p-2 btn btn-primary text-white btn-sm me-2' to={'/create-plot'}><i class="fa-solid fa-plus"></i> Create a Plot</Link>
          {authToken ?
            <button to="" className='p-2 btn btn-success text-white btn-sm' onClick={toggleDropdown}>
              <i class="fa-solid fa-bars"></i> Menu
              {isOpen &&
                <motion.ul
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ scale: 0, opacity: 0 }}
                  transition={{ duration: 0.5, type: "spring" }}>
                  <li><Link className='text-white fw-normal' to="/admin"><i class="fa-solid fa-user"></i> admin</Link></li>
                  <li><Link className='text-white fw-normal' to="/my-events"><i class="fa-solid fa-calendar-days"></i> My Events</Link></li>
                  <li><Link className='text-white fw-normal' to="/my-revenue"><i class="fa-solid fa-money-bill"></i> My Revenue</Link></li>
                  <li><Link className='text-white fw-normal' to="/my-payment-requests"><i class="fa-solid fa-money-bill"></i> My Payment Requests</Link></li>
                  <li><Link className='text-white fw-normal' to="/accounts/profile"><i class="fa-regular fa-id-badge"></i> My Profile</Link></li>
                  <li onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"></i> Logout</li>
                </motion.ul>
              }
            </button>
            :
            <Link className='p-2 btn btn-success text-white btn-sm' to={'/login'}><i class="fa-solid fa-right-to-bracket"></i> Sign In</Link>
          }
        </div>
      </div>
      <div className='phoneMenu justify-content-between align-items-center'>
        <div className='d-flex gap-1'>
          <Link to={"/"} className='link'><img src='/images/logo.png' alt='logo' /></Link>
          <span className='hr'></span>
          <button onClick={() => setSearchMobile(!searchMobile)} className='d-flex text-secondary align-items-center'>
            <span class="material-icons">search</span>
            <span>search</span>
          </button>
          {authToken ?
            <Link to="/create-plot" className='d-flex linked fw-normal gap-1 align-items-center text-secondary'>
              <span class="material-icons">add_circle</span>
              <span>Create a Plot</span>
            </Link>
            :
            <Link to="/login" className='d-flex linked fw-normal gap-1 align-items-center text-secondary'>
              <span class="material-icons">login</span>
              <span>login</span>
            </Link>
          }
        </div>
        <button className='d-block bg-transparent d-md-none border-0 d-flex align-items-center justify-content-center text-success' onClick={toggleDropdownMobile}>
          <span class="material-icons">menu</span>
        </button>
        <AnimatePresence>
          {searchMobile && (
            <motion.form
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration: 0.3 }}
              onSubmit={handleSearch}>
              <button className='bg-transparent border-0' onClick={() => setSearchMobile(false)}>
                <span class="material-icons">close</span>
              </button>
              <input type="search" value={searchQuery} placeholder='Search Events by Event Name or Location' onChange={(e) => setSearchQuery(e.target.value)} />
              <button type="submit" className='btn btn-secondary'>search</button>
            </motion.form>
          )}
          {isOpenMobile && (
            <motion.div
              className="mobile-menu"
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.3 }}
            >
              <ul>
                <li><Link to="/help-center">Help Center</Link></li>
                <li><Link to="/how-it-works">How It Works</Link></li>
                <li><Link to="/create-plot">Create a Plot</Link></li>
                {authToken ? (
                  <>
                    <li><Link to="/admin">Admin</Link></li>
                    <li><Link to="/my-events">My Events</Link></li>
                    <li><Link to="/my-revenue">My Revenue</Link></li>
                    <li><Link to="/my-payment-requests">My Payment Requests</Link></li>
                    <li><Link to="/accounts/profile">My Profile</Link></li>
                    <li onClick={handleLogout}><Link to="#">Logout</Link></li>
                  </>
                ) : (
                  <li><Link to="/login">Sign In</Link></li>
                )}
              </ul>
            </motion.div>
          )}
        </AnimatePresence>

      </div>
      <div className='navItems'>
        <Navigator />
      </div>
    </div>

  )
}

export default Header
/*
  <button className='d-block bg-transparent d-md-none border-0 d-flex align-items-center justify-content-center' onClick={toggleDropdownMobile}>
    <span class="material-icons">menu</span>
  </button>

  {isOpenMobile && (
    <motion.div
      className="mobile-menu"
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{ duration: 0.3 }}
    >
      <ul>
        {authToken ? (
          <>
            <li><Link to="/admin">Admin</Link></li>
            <li><Link to="/my-events">My Events</Link></li>
            <li><Link to="/my-revenue">My Revenue</Link></li>
            <li><Link to="/my-payment-requests">My Payment Requests</Link></li>
            <li><Link to="/accounts/profile">My Profile</Link></li>
            <li onClick={handleLogout}><Link to="#">Logout</Link></li>
          </>
        ) : (
          <li><Link to="/login">Sign In</Link></li>
        )}
        <li><Link to="/help-center">Help Center</Link></li>
        <li><Link to="/how-it-works">How It Works</Link></li>
        <li><Link to="/create-plot">Create a Plot</Link></li>
      </ul>
    </motion.div>
  )}
*/