import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';

const CurrencyForm = ({ onAddOrUpdate, name, setName, editingId, setEditingId, inputRef, handleCancleEdit, postRequest, putRequest, setLoading }) => {

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name.trim()) return;
        setLoading(true)
        if (editingId) {
            const formData = new FormData()
            formData.append("name", name)
            const response = await putRequest(`${domain}/currencies/${editingId}`, formData);
            setEditingId(null);
            onAddOrUpdate(response);
        } else {
            const formData = new FormData()
            formData.append("name", name)
            const response = await postRequest(`${domain}/currencies`, formData);
            onAddOrUpdate(response);
        }
        setName('');
        setLoading(false)
    };

    return (
        <form onSubmit={handleSubmit} className='mb-2 d-flex align-items-center gap-2'>
            <input
                ref={inputRef}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Category name"
            />
            <button type="submit" className='btn btn-sm btn-success'>{editingId ? 'Update' : 'Add'}</button>
            <button type='button' disabled={editingId ? false: true} onClick={handleCancleEdit} className='btn btn-sm btn-danger ms-2'>canel update</button>
        </form>
    );
};

const CurrencList = ({ categories, onDelete, onEdit }) => {
    return (
        <table>
            <tr>
                <th style={{width:50}}>No</th>
                <th>Name</th>
                <th style={{width:"100px"}}>Action</th>
            </tr>
            {categories.length > 0 && categories.map((category, index) => (
                <tr key={category.id} className='item'>
                    <td>{index + 1}</td>
                    <td>{category.name}</td>
                    <td>
                        <div className='d-flex gap-2'>
                            <button onClick={() => onDelete(category.id)} className='btn btn-sm btn-danger'>Delete</button>
                            <button onClick={() => onEdit(category)} className='btn btn-sm btn-success'>Edit</button>
                        </div>
                    </td>
                </tr>
            ))}
        </table>
    );
};

const CurrencyComponent = () => {
    const {setLoading} = useLoadingContext()
    const inputRef = useRef()
    const [name, setName] = useState('');
    const [editingId, setEditingId] = useState(null);
    const [categories, setCategories] = useState([]);
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchCategories();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };
    
        fetchData();
    }, []);

    const fetchCategories = async () => {
        const response = await  getRequest(`${domain}/currencies`);
        setCategories(response);
    };

    const handleAddOrUpdate = (category) => {
        const index = categories.findIndex((c) => c.id === category.id);
        if (index === -1) {
            setCategories([...categories, category]);
        } else {
            const newCategories = [...categories];
            newCategories[index] = category;
            setCategories(newCategories);
        }
    };

    const handleDelete = async (id) => {
        if(window.confirm("are your sure you want to delete this item")){
            setLoading(true)
            const response = await deleteRequest(`${domain}/currencies/${id}`);
            setCategories(categories.filter((category) => category.id !== id));
            setLoading(false)
        }

    };

    const handleEdit = (category) => {
        setName(category.name);
        setEditingId(category.id);
        inputRef.current.focus()
    };

    const handleCancleEdit = () => {
        setName("");
        setEditingId(null);
        inputRef.current.focus()
    };

    return (
        <div className='w-100'>
            <CurrencyForm onAddOrUpdate={handleAddOrUpdate} name={name} setName={setName} editingId={editingId} setEditingId={setEditingId} inputRef={inputRef} handleCancleEdit={handleCancleEdit} postRequest={postRequest} putRequest={putRequest} setLoading = {setLoading} />
            
            <CurrencList
                categories={categories}
                onDelete={handleDelete}
                onEdit={handleEdit}
            />
        </div>
    );
};

export default CurrencyComponent;