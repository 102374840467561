import React, {useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import "../css/Navigator.css"

const Navigator = () => {
    /*
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const offset = window.scrollY;
        setIsVisible(offset > 100);
      };
  
      // Attach the event listener when the component mounts
      window.addEventListener('scroll', handleScroll);
  
      // Detach the event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
    */
    const links = [
        {
            id: 1,
            name: 'Trending',
            icon: '',
        },
        {
            id: 1,
            name: 'Movies',
            icon: '',
        },
        {
            id: 1,
            name: 'Tv Series',
            icon: '',
        },
        {
            id: 1,
            name: 'Search',
            icon: '',
        }
    ]
    return (
        <div className={`navigator d-flex align-items-center text-center gap-4 `}>
            <Link to={`/`}>
                <i className="fa-solid fa-home"></i>
                <span>Home</span>
            </Link>
            <Link to={`/categories/movies`}>
                <i className="fa-solid fa-file-video"></i>
                <span>Movies</span>
            </Link>
            <Link to={`/categories/sports`}>
                <i className="fa-solid fa-medal"></i>
                <span>Sports</span>
            </Link>
            <Link to={`/categories/health`}>
                <i className="fa-solid fa-notes-medical"></i>
                <span>Health</span>
            </Link>
            <Link to={`/categories/music`}>
                <i className="fa-solid fa-music"></i>
                <span>Music</span>
            </Link>
            <Link to={`/categories/business`}>
                <i className="fa-solid fa-business-time"></i>
                <span>Business</span>
            </Link>
            <Link to={`/categories/conferences`}>
                <i className="fa-solid fa-place-of-worship"></i>
                <span>Conferences</span>
            </Link>
            <Link to={`/categories/tourism-and-experience`}>
                <i className="fa-solid fa-hippo"></i>
                <span>Tourism</span>
            </Link>
            
            <Link className='d-none' to={`/search`}>
                <i className="fa-solid fa-magnifying-glass"></i>
                <span>Search</span>
            </Link>
        </div>
    )
}

export default Navigator