import React, { useContext, useState, useEffect } from 'react'
import "../css/Login.css"
import { Link, useNavigate } from 'react-router-dom';
import { domain } from './Domain'
import { AuthContext } from './AuthContext'
import useApiRequest from './useApiRequest'
import { motion, AnimatePresence } from 'framer-motion';
import notify from './Nots'
import withAuthCheck from './WithAuthCheck';
import Header from './Header';

const Login = () => {
    const { postRequest, loading, setLoading } = useApiRequest()
    const { authToken, login, logout } = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isLoginFormValid = username.trim() !== '' && password.trim() !== '';
    const [err, setErr] = useState(false)
    const navigate = useNavigate(); // Hook for navigation

    const handleLogin = async (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append("username", username)
        formData.append("password", password)
        setLoading(true)

        const req = await postRequest(`${domain}/accounts/login`, formData)
        console.log(req)
        if (req['message']) {
            notify(JSON.stringify(req['message']), "error")
        } else if (req['non_field_errors']) {
            notify(req['non_field_errors'][0], "error")
            setTimeout(() => setErr(false), 3000)
        } else if (req['token']) {
            e.target.reset()
            setUsername("")
            setPassword("")
            notify("login successfull, redirecting ...", "info")
            setErr(`login successfull, redirecting ...`)
            setTimeout(() => {
                login(req)
                navigate('/')
            }, 3000)
        }
        setLoading(false)
    }

    return (
        <>
            <Header />
            <div id='loginForm' className='animate__animated animate__fadeIn'>
                <form onSubmit={handleLogin}>
                    <img src="images/logo.png" class="w-50 d-none m-auto text-center" alt="logo" />
                    <h2 class="fw-bold fs-5 pt-2 pb-2 text-center">Sign in to EventPlots</h2>
                    {err &&
                        <AnimatePresence>
                            <motion.p key={err} initial={{ opacity: 0 }} animate={{ opacity: 1 }} className='alert alert-info'>{err}</motion.p>
                        </AnimatePresence>
                    }
                    <div class="border rounded mb-2 p-3">
                        <label class="pb-1">Account username</label>
                        <div class="floating-label d-none">
                            <input type="text" id="input-field" placeholder=" " />
                            <label for="input-field">Username</label>
                        </div>
                        <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="username" />
                        <label class="pb-1">Account password</label>
                        <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="password" />
                        <button
                            type='submit'
                            className={`btn ${isLoginFormValid === false && "text-white bg-secondary"} py-2 mb-2`}
                            disabled={!isLoginFormValid}>
                            <i class="fa-solid fa-right-to-bracket"></i> {loading ? "Logining You In" : "Login"}
                        </button>
                        <Link to={'/forgot-password'} className='d-flex justify-content-end text-danger'>Forgot Password ?</Link>
                    </div>

                    <div class="d-flex justify-content-center border rounded p-2">
                        <p class="pe-2 m-0"><small class="fs-6">New to Eventplots?</small> </p>
                        <Link class="text-primary text-decoration-none" to={'/register'}><small class="fs-6">Create an Account</small></Link>
                    </div>

                    <div class="text-center links">
                        <hr />
                        <a href="" class="text-decoration-none me-2">Conditions of Use </a>
                        <a href="" class="text-decoration-none me-2">Privacy Notice</a>
                        <a href="" class="text-decoration-none">How To</a>
                        <p>Copyright &copy; {new Date().getFullYear()} eventPlots</p>
                    </div>
                </form>
            </div>
        </>

    )
}

// Wrap the Login component with the HOC
export default withAuthCheck(Login);