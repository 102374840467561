import React, { useState, useEffect } from 'react';
import "../css/PaymentDetailsForm.css"

const PaymentDetailsForm = ({ paymentDetails, onSubmit, onCancel, editing }) => {
    // Initialize state with provided payment details (if any)
    const initial = {
        account_type: paymentDetails?.account_type || 'bank',
        bank_name: paymentDetails?.bank_name || '',
        bank_branch: paymentDetails?.bank_branch || '',
        account_number: paymentDetails?.account_number || '',
        account_name: paymentDetails?.account_name || '',
        network_name: paymentDetails?.network_name || '',
        contact: paymentDetails?.contact || '',
        slug: paymentDetails?.slug || ''
    }
    const [formData, setFormData] = useState(initial);

    const banksInUganda = [
        "Stanbic Bank Uganda",
        "Centenary Bank",
        "DFCU Bank",
        "ABSA Bank Uganda",
        "Equity Bank Uganda",
        "Bank of Africa Uganda",
        "Standard Chartered Bank Uganda",
        "Housing Finance Bank",
        "Cairo Bank Uganda",
        "PostBank Uganda",
        "Uganda Development Bank",
        "Tropical Bank",
        "Exim Bank Uganda",
        "Finance Trust Bank",
    ];

    // Handle account type change
    const handleAccountTypeChange = (event) => {
        const { value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            account_type: value
        }));
    };

    // Handle input change for other fields
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();
        if(formData.account_type === 'bank'){
            if (!formData.account_number || !formData.account_name ||  !formData.bank_name) {
                alert("Please fill in all required fields.");
                return;
            }
        }
        onSubmit(formData);
    };


    return (
        <div className='PaymentDetailsForm'>
            <form onSubmit={handleSubmit}>
                <div className="mb-3 d-flex gap-4">
                    <label >Account Type</label>
                    <select
                        name="account_type"
                        value={formData.account_type}
                        onChange={handleAccountTypeChange}
                        
                    >
                        <option value="bank">Bank Account</option>
                        <option value="mobile_money">Mobile Money</option>
                    </select>
                </div>

                {formData.account_type === 'bank' && (
                    <div>
                        <h3>Bank Account Information</h3>
                        <div className="mb-3 d-block d-md-flex gap-4">
                            <label >Bank Name:</label>
                            <select name="bank_name" value={formData.bank_name} onChange={handleInputChange} >
                                <option value="" className='text-white' disabled>Select a bank</option>
                                {banksInUganda.map(bank =>
                                    <option key={bank} value={bank}>{bank}</option>
                                )}
                            </select>
                        </div>
                        <div className="mb-3 d-block d-md-flex gap-4">
                            <label >Bank Branch:</label>
                            <input
                                type="text"
                                name="bank_branch"
                                value={formData.bank_branch}
                                onChange={handleInputChange}
                                placeholder="Enter bank branch"
                            />

                        </div>
                        <div className="mb-3 d-block d-md-flex gap-4">
                            <label >Account Number:</label>
                            <input
                                type="text"
                                name="account_number"
                                value={formData.account_number}
                                onChange={handleInputChange}
                                placeholder="Enter account number"
                                required
                            />

                        </div>
                        <div className="mb-3 d-block d-md-flex gap-4">
                            <label >Account Name:</label>
                            <input
                                type="text"
                                name="account_name"
                                value={formData.account_name}
                                onChange={handleInputChange}
                                placeholder="Enter account name"
                                required
                            />

                        </div>
                    </div>
                )}

                {formData.account_type === 'mobile_money' && (
                    <div>
                        <h3>Mobile Money Information</h3>
                        <div className="mb-3 d-flex gap-4">
                            <label >Network Name:</label>
                            <input
                                type="text"
                                name="network_name"
                                value={formData.network_name}
                                onChange={handleInputChange}
                                placeholder="Enter network name"
                                required
                            />

                        </div>
                        <div className="mb-3 d-flex gap-4">
                            <label >Contact:</label>
                            <input
                                type="text"
                                name="contact"
                                value={formData.contact}
                                onChange={handleInputChange}
                                placeholder="Enter contact number"
                                required
                            />

                        </div>
                    </div>
                )}

                <div>
                    <button type="submit"><i class="fa-solid fa-right-to-bracket"></i> {editing ? 'Update' : 'Save'}</button>
                    {editing && <button type="button" id='cancelBtn' className='ms-3' onClick={onCancel}><i class="fa-solid fa-right-to-bracket"></i> Cancel Edit</button>}
                </div>
            </form>
        </div>
    );
};

export default PaymentDetailsForm;
