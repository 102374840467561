import React, { useState, useEffect, useRef } from 'react';
import useApiRequest from './useApiRequest';
import { domain } from './Domain';
import { useLoadingContext } from './LoadingContext';

const ContactList = ({ contacts, onDelete }) => {
    return (
        <div>
            {contacts.length > 0 && contacts.map((contact, index) => (
                <div key={contact.id} className='item border-bottom pb-2'>
                    <p className='m-0 fw-bold text-muted'>Name: {contact.name}</p>
                    <p className='m-0 fw-bold text-muted'>Subject: {contact.subject}</p>
                    <p className='m-0 fs-6 text-muted fs-6'>Message:<br />{contact.message}</p>
                    <div className='d-flex gap-2'>
                        <small>{contact.created_at}</small>
                        <small>{contact.address}</small>
                    </div>
                    <div className='d-flex gap-2'>
                        <button onClick={() => onDelete(contact.id)} className='btn btn-sm mt-2 btn-outline-danger'>Delete</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

const ContactComponent = () => {
    const { setLoading } = useLoadingContext()
    const [contacts, setContacts] = useState([]);
    const { getRequest, deleteRequest } = useApiRequest();

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchContacts();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchContacts = async () => {
        const response = await getRequest(`${domain}/contact`);
        setContacts(response);
    };

    const handleAddOrUpdate = (contact) => {
        const index = contacts.findIndex((c) => c.id === contact.id);
        if (index === -1) {
            setContacts([...contacts, contact]);
        } else {
            const newContacts = [...contacts];
            newContacts[index] = contact;
            setContacts(newContacts);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm("are your sure you want to delete this item")) {
            setLoading(true)
            const response = await deleteRequest(`${domain}/contact/${id}`);
            setContacts(contacts.filter((contact) => contact.id !== id));
            setLoading(false)
        }

    };

    return (
        <div className='w-100'>
            <ContactList
                contacts={contacts}
                onDelete={handleDelete}
            />
        </div>
    );
};

export default ContactComponent;