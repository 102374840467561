import React, {useState, useEffect} from 'react'
import Header from './Header'
import AppHead from './AppHead'
import { domain } from './Domain'
import useFetch from './useFetch'
import { useNavigate } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../css/HowItWorks.css"
import Footer from './Footer'

const HowItWorkSkeleton = () => {
  return(
    <div className='mt-4 mb-4 div'>
        <SkeletonTheme baseColor="#ddd" highlightColor="#5555">
            <Skeleton height={200} count={1} />
            <div>
                <Skeleton height={20} count={1} />
                <Skeleton height={10} count={1} />
                <Skeleton height={10} width={200} count={1} />
            </div>
        </SkeletonTheme>
    </div>
  )
}

const How = ({item}) => {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate()
  const handleImageLoad = () => {
      setLoaded(true);
  };

  const truncateString = (str) => {
    if (str.length <= 90) return str;
  
    // Truncate the string to 90 characters
    let truncated = str.substring(0, 90);
  
    // Find the last space in the truncated string
    const lastSpaceIndex = truncated.lastIndexOf(' ');
  
    // If there's a space, truncate to that index; otherwise, keep it as is
    if (lastSpaceIndex > -1) {
      truncated = truncated.substring(0, lastSpaceIndex);
    }
  
    return truncated + " ...";
  };
  
  return(
    <div className='div' title={`click to watch ${item.title}`}>
      {loaded === false && <Skeleton height={250} count={1} />}
      <img 
        src={domain + item.cover} 
        alt='img' 
        onLoad={handleImageLoad}
        //loading='lazy'
        style={{ display: loaded ? 'block' : 'none' }}
        onClick={()=>navigate(`/how-it-works/${item.slug}`)}/>
      <div className='p-2 bg-light mb-3'>
        <figcaption className='fw-bold'>{item.title}</figcaption>
        <p className='lead' style={{fontSize:"0.9rem"}}>{truncateString(item.description)}</p>
      </div>  
    </div>
  )
}

const HowItWorks = () => {
  const { data, loading } = useFetch(`${domain}/how-it-works`)
  const items = Array(8).fill(null);
  //const [loading, setLoading] = useState(true)
  return (
    <div className='animate__animated animate__fadeIn'>
      <Header />
      <AppHead
        title="How it Works"
        description="How to use EventPlots Easily"
        imageUrl="http://localhost:3000/images/logo.png"
        url={window.location.hostname}
        twitterCardType="summary_large_image"
      />
      <div className='container-lg howItWorks pt-4 pb-4'>
        <h1 className='fw-bolder'>A complete guide to EventPlots</h1>
        <p className='lead fs-6'>EventPlots is an innovative platform designed to streamline the process of planning and executing events with efficiency and precision. This comprehensive guide aims to provide users with a step-by-step walkthrough, offering detailed insights into the functionalities and features of EventPlots. Whether you are a seasoned event organizer or a newcomer to the world of event planning, this guide will empower you to harness the full potential of EventPlots, allowing you to create, manage, and elevate your events seamlessly. From understanding the fundamental concepts to mastering advanced techniques, embark on a journey with this guide to unlock the full capabilities of EventPlots and enhance your event planning experience</p>
        <div className='four'>
          {loading ? items.map((item, index) => (<HowItWorkSkeleton key={index}/>)) :
            data.map((item)=> <How key={item.id} item={item}/>)
          }
        </div>
      
      </div>
      <Footer />
    </div>
  )
}

export default HowItWorks