import React, { useState, useEffect } from 'react'
import Header from './Header'
import { Filter, Categories, FilterDistrict, Card } from './Entry'
import { useLoadingContext } from './LoadingContext';
import { domain } from './Domain';
import useApiRequest from './useApiRequest';
import { useParams } from 'react-router-dom';
import { useFilterContext } from './FilterContext';
import { filterEvents } from './EventFilter';
import { motion } from "framer-motion"
import Footer from './Footer';

const SingleCategory = () => {
    const { filterName, setFilterName, eventDate, setEventDate } = useFilterContext()
    let { name } = useParams();
    const { setLoading } = useLoadingContext()
    const [events, setEvents] = useState([])
    const [filteredData, setFilteredData] = useState(events);
    const { postRequest, getRequest, putRequest, deleteRequest } = useApiRequest();

    const truncateString = (str) => {
        return str.length > 90 ? str.substring(0, 89) + "..." : str;
    }

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch categories
                await fetchCategories();
            } catch (error) {
                console.error('Error fetching categories:', error);
            } finally {
                // Set loading to false when the request is completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, [name]);


    useEffect(() => {
        if (filterName && events && events.length > 0) {
            const filtered = events.filter(event =>
                event.venue_name.toLowerCase().includes(filterName.toLowerCase()) ||
                event.venue_address.toLowerCase().includes(filterName.toLowerCase())
            );
            if (eventDate != "all" && events.length > 0) {
                const filtered2 = filterEvents(eventDate, filtered)
                setFilteredData(filtered2);
            } else {
                setFilteredData(filtered);
            }
        } else {
            if (eventDate != "all" && events && events.length > 0) {
                const filtered2 = filterEvents(eventDate, events)
                setFilteredData(filtered2);
            } else {
                setFilteredData(events);
            }
        }
    }, [events, filterName, eventDate])

    const fetchCategories = async () => {
        const response = await getRequest(`${domain}/event-details?category=${name}`);
        setEvents(response);
    };

    return (
        <div key={name} className='animate__animated animate__fadeIn'>
            <Header />
            <div className='ps-3 pt-4 pe-3'>
                <div className='animate__animated animate__fadeIn'>
                    <h3 className='text-secondary fw-bold' >Viewing {name.replace(/-/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())} Category Events</h3>
                    <p className='d-none lead fs-6 fw-light'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, </p>
                    <motion.div
                        variants={{
                            initial: {
                            scale: 0.5,
                            y: 50,
                            opacity: 0,
                            },
                            animate: {
                            scale: 1,
                            y: 0,
                            opacity: 1,
                            },
                        }}
                        initial="initial"
                        animate="animate"
                        transition={{
                        staggerChildren: 0.005,
                        }}
                    className='row'>
                        {filteredData && filteredData.length > 0 && filteredData.map(item =>
                            <div key={item.id} className='col-lg-3'>
                                <Card
                                    image={`${domain}${item.listing_img}`}
                                    category={item.event_category.name}
                                    title={item.event_plot_name}
                                    slug={item.slug}
                                    description={truncateString(item.event_description)}
                                    date={formatDate(new Date(item.start_date_event))}
                                    venue={item.venue_name}
                                    organiser={item.organiser.organiser_name}
                                    views={item.views}
                                    likes={item.likes}
                                />
                            </div>
                        )}

                    </motion.div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SingleCategory

/*
    <Card
        image={'https://estudentshop.com/static/images/pc2.jpg'}
        title={'we the best'}
        description={'Contrary to popular belief, Lorem Ipsum is not simply random text. It…'}
        date={'March 9, 2023, midnight'}
        venue={'makerere - uganda'}
        organiser={'Bam Events'}
        category={"movies"}
    />
*/