import React, { useState, useEffect } from 'react';
import '../css/Slider.css';
import { AnimatePresence, motion } from "framer-motion"
import { domain } from './Domain';
import { Link } from 'react-router-dom';

const Slider = ({ slides }) => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? slides.length - 1 : prevSlide - 1
        );
    };

    useEffect(() => {
        const autoSlide = setInterval(nextSlide, 10000); // Auto-slide every 5 seconds
        return () => clearInterval(autoSlide); // Cleanup on unmount
    }, []);

    const getSlidesToDisplay = () => {
        const slidesToDisplay = [];
        for (let i = 0; i < 3; i++) {
            slidesToDisplay.push(slides[(currentSlide + i) % slides.length]);
        }
        return slidesToDisplay;
    };

    const slidesToDisplay = getSlidesToDisplay();

    const truncateString = (str, len) => {
        return str.length > len ? str.substring(0, len-1) + "..." : str;
    }

    return (
        <div className="slider flex-wrap col-lg-8">
            <div className="slides-container">
                <AnimatePresence mode="wait">
                    {slidesToDisplay.map((slide, index) => (
                        <motion.div
                            key={Math.random() * 10}
                            initial={{ opacity: 0.5 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0.5 }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            className={`slide ${index === 0 ? 'large' : 'small'
                                }`}
                        >
                            <img src={domain+slide.banner} alt={slide.alt} className='d-none'/>
                            <Link to={`/events/${slide.slug}`}>
                                <img src={domain + slide.banner} alt={slide.alt} />
                            </Link>
                            <div className="caption">
                                <Link to={`/events/${slide.slug}`}><h3>{slide.event_plot_name}</h3></Link>
                                <p>{index === 0 ? truncateString(slide.event_description, 170): truncateString(slide.event_description, 70)}</p>
                            </div>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </div>
            <div className='w-100 d-flex gap-2 p-2 justify-content-center'>
                <button className="prev" onClick={prevSlide}>
                    &#10094;
                </button>
                <button className="next" onClick={nextSlide}>
                    &#10095;
                </button>
            </div>
        </div>
    );
};

export default Slider;