import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import '../css/MyEvents.css';
import { domain } from './Domain';
import notify from './Nots';
import useFetch from './useFetch';
import useApiRequest from './useApiRequest';
import { useNavigate } from 'react-router-dom';
import { useLoadingContext } from './LoadingContext';
import { saveToLocalStorage } from './EncryptDecrypt';
import { Button, Modal } from 'react-bootstrap';
import '../css/RevenueDashboard.css'; // We'll define the CSS here
import PaymentDetailsForm from './PaymentDetailsForm';

const RevenueDashboard = () => {
    const { getRequest, postRequest, putRequest } = useApiRequest()
    const { setLoading } = useLoadingContext()
    const [accountView, setAccountView] = useState(false)
    const { data, loading, setLoading: setLoadingData, refresh, setRefresh } = useFetch(`${domain}/my-event-earnings`)
    const [totalAmount, setTotalAmount] = useState(0);
    const [event, setEvent] = useState(null)
    const [showModal, setShowModal] = useState(false);
    const [paymentRequestAmount, setPaymentRequestAmount] = useState(0);
    const [userBalance, setUserBalance] = useState(0); // Represents the user's collected balance

    const [paymentDetails, setPaymentDetails] = useState(null); // Optional: to load existing payment details
    const [myAccounts, setMyAccounts] = useState([])
    const [editing, setEditing] = useState(null)
    useEffect(() => {
        const total = data && data.reduce((sum, event) => sum + event.amount, 0);
        setTotalAmount(total);
        console.log(data)
    }, [data])

    const initRequest = async (item) => {
        if (item.amount <= 0) {
            notify("less money to push request", "error")
        } else {
            setShowModal(true)
            setUserBalance(item.amount - item.total_paid)
            setEvent(item)
        }
    }

    const handleRequest = async () => {
        if (paymentRequestAmount > userBalance) {
            notify("Your request for payment is way above what you collected", "error")
            return
        }
        setLoading(true)
        setShowModal(false)
        const formData = new FormData()
        formData.append("event", event.event['eventId'])
        formData.append("amount", paymentRequestAmount)
        formData.append("total_available", event.amount)
        formData.append("total_paid", event.total_paid)
        const response = await postRequest(`${domain}/payment-requests`, formData)
        if (response) {
            notify(JSON.stringify(response['message']), response['status'] === "success" ? "info" : "error")
            setLoading(false)
            setUserBalance(0)
            setPaymentRequestAmount(0)
            setEvent(null)
            setRefresh(!refresh)
        }
    }

    const handleViewAccount = async (status) => {
        if (status === false) {
            setAccountView(true)
            const response = await getRequest(`${domain}/payment-details?q=details`)
            if (response['status'] === 'ok') {
                setMyAccounts(response['message']);
            } else {
                notify(response['message'])
            }
        } else {
            setAccountView(false)
        }
    }

    const handleSubmit = async (data) => {
        console.log("Submitted Payment Data:", data);
        //process data send to backend

        const formData = new FormData()
        // Loop through each property in the initialUserData object
        for (const [key, value] of Object.entries(data)) {
            // Append each key-value pair to the FormData object
            formData.append(key, value);
        }
        setLoading(true)
        let response;

        if (editing) {
            response = await putRequest(`${domain}/payment-details/${editing}`, formData)
        } else {
            response = await postRequest(`${domain}/payment-details`, formData)
        }

        if (response.status === "ok") {
            notify(JSON.stringify(response.data), "info")
            setPaymentDetails(response.data)
            const found = myAccounts.findIndex(item => parseInt(item.id) === parseInt(editing))
            myAccounts[found] = response.data;
        } else {
            notify(JSON.stringify(response.data), "error")
        }
        setLoading(false)
    };

    const editAccount = (id) => {
        const selectedAccount = myAccounts.find(item => parseInt(item.id) === parseInt(id));
        if (selectedAccount) {
            setPaymentDetails(selectedAccount);
            setEditing(id)
        }
    }

    const onCancel = () => {
        setEditing(null);
        setPaymentDetails(null)
    }

    const MemoizedPaymentDetailsForm = React.memo(PaymentDetailsForm);

    return (
        <>
            <Header />
            <div className='container-lg pt-4 pb-4'>
                {accountView ?
                    <h5 className='h5'>My Account - <span className='d-inline'>Information</span></h5>
                    :
                    <h5 className='h5'>My Registered Events - <span className='d-inline text-warning'>Total of {totalAmount && totalAmount.toLocaleString()}/= collected</span></h5>
                }
                <div className='rev d-flex gap-4 align-items-start'>
                    {accountView ?
                        <div className='eventsRegistered gap-2'>
                            <div className='d-flex gap-2 ps-4 pt-2'>
                                {myAccounts.length > 0 && myAccounts.map((account) =>
                                    account.account_type === 'bank' ?
                                        <button className='btn btn-sm btn-outline-info fw-bold' onClick={() => editAccount(account.id)} key={account.id}>
                                            <p>{account.bank_name} <br /> Account </p>
                                        </button>
                                        :
                                        <button className='btn btn-sm btn-outline-secondary fw-bold' onClick={() => editAccount(account.id)} key={account.id}>
                                            <p>{account.network_name}<br />Account</p>
                                        </button>
                                )}
                            </div>
                            <MemoizedPaymentDetailsForm
                                paymentDetails={paymentDetails}
                                onSubmit={handleSubmit}
                                onCancel = {onCancel}
                                editing = {editing}
                            />
                        </div>
                        :
                        <div className='eventsRegistered d-flex gap-4 flex-wrap'>
                            {data && data.map(item =>
                                <div key={item.id} className='d-flex evMain' onClick={() => initRequest(item)}>
                                    <img src={domain + item.event.banner} />
                                    <div className='d-flex flex-column justify-content-between'>
                                        <figcaption className='fw-bold ps-2 pe-2'>{item.event['event-name']}</figcaption>
                                        <small className='text-muted ps-2 pe-2'>{item.event.description}</small>
                                        <b className='d-block ps-2 pe-2'>Ugx {item.amount.toLocaleString()}/=</b>
                                        <p className='bg-success text-white'>paid: {item.total_paid ? item.total_paid.toLocaleString() : 0}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    <div className='eventsRegisteredLast'>
                        <h5>Account Details Disclaimer!</h5>
                        <div>
                            <button className={`btn ${accountView ? 'btn-success' : 'btn-warning'} text-white mb-2`} onClick={() => handleViewAccount(accountView)}>{accountView ? "View My Event Payments" : "View Account Information"}</button>
                        </div>
                        <p style={{ textAlign: "justify" }}><i>You have access to manage and view your account details. To view your account information, click "View Account Information".</i></p>
                        <p>If you need to update any details or make changes to your accounts, simply click "Update Account". You can also delete your account if needed.</p>
                        <p>Please ensure your information is accurate and up-to-date. If you have any questions or need assistance, feel free to contact our support team.</p>
                    </div>
                </div>
            </div>


            {/* Payment Request Modal */}
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton className='bg-success text-white'>
                    <Modal.Title style={{ textTransform: "capitalize" }}>{event && event.event['event-name']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h5 className='text-danger'>Available Balance: Ugx {userBalance && userBalance.toLocaleString()}/=</h5>
                        <p>Please ensure to enter right information as we are not responsible for any errors that you perform</p>
                        <p>Total Collected: {event && event.amount.toLocaleString()}</p>
                        <p>Total Paid: {event && event.total_paid > 0 ? event.total_paid.toLocaleString() : 0}</p>
                        <div className="payment-form">
                            <label htmlFor="amount">Enter Amount to Request:</label>
                            <input
                                type="number"
                                id="amount"
                                value={paymentRequestAmount}
                                onChange={(e) => setPaymentRequestAmount(Number(e.target.value))}
                                className="form-control"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleRequest}>
                        Submit Payment Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default RevenueDashboard;
