import React from 'react';
import '../css/Footer.css';
import { Link } from 'react-router-dom';
import useFetch from './useFetch'
import { domain } from './Domain'
import Slider from './Slidder';

const Footer = () => {
    const { data, loading, error, refresh, setRefresh } = useFetch(`${domain}/event-categories`);
    //https://via.placeholder.com/800x400.png?text=Slide+5
    return (
        <>
            <footer className="Footer">
                <div className="FooterSection">
                    <h3>About EventPlots</h3>
                    <p>EventPlots is Uganda’s premier ticketing platform, offering seamless event booking, management, and discovery. Enjoy a user-friendly, secure, and reliable experience tailored to meet all your event needs effortless</p>
                </div>
                <div className="FooterSection">
                    <h3>categories</h3>
                    {data && data.map((category, index) => {
                        return (
                            <p><Link
                                to={`/categories/${category.name.toLowerCase().replace(/\s+/g, '-')}`}
                                className='flex-grow-1 text-white'
                                key={index}>{category.name}</Link></p>
                        )
                    })}
                </div>
                <div className="FooterSection">
                    <h3>Learn More</h3>
                    <p><Link className='text-white' to={'/how-it-works'}>How it works</Link></p>
                    <p><Link className='text-white' to={'/help-center'}>Why EventPlots</Link></p>
                    <p><Link className='text-white' to={'/frequently-asked-questions'}>Frequently Asked Questions</Link></p>
                </div>
                <div className="FooterSection">
                    <h3>Contact Us</h3>
                    <p>Email: info@eventplots.com</p>
                    <p>Phone: 0200-905932</p>
                </div>
            </footer>
            <div className='last'>
                <div>
                    <p className='d-none d-md-block'>Copyright &copy; EventPlots</p>
                    <p>Privacy Policy</p>
                    <p>Terms <span className='d-none d-md-inline'>& Conditions</span></p>
                </div>
                <p>Powered by Graphite IT</p>
            </div>
        </>
    );
};

export default Footer;
