import React, { useState } from 'react'
import Header from './Header'
import "../css/Admin.css"
import { motion } from 'framer-motion'
import CategoryComponent from './CategoryComponent';
import CurrencyComponent from './CurrencyComponent';
import TicketTypeComponent from './TicketTypeComponent';
import TicketCategoryComponent from './TicketCategoryComponent';
import ContactComponent from './ContactComponent';
import FeedbackComponent from './FeedbackComponent';
import HowItWorksComponent from './HowItWorksComponent';
import Footer from './Footer';

const Admin = () => {
  const buttons = [
    { name: "Event Categories", icon: "category" },
    { name: "Currencies", icon: "money" },
    { name: "Ticket Type", icon: "format_list_bulleted" },
    { name: "Ticket Category", icon: "format_list_bulleted" },
    { name: "How It Works", icon: "info" },
    { name: "Feedback", icon: "feedback" },
    { name: "Contact", icon: "contacts" }
  ];
  const [handleView, sethandleView] = useState("Event Categories")
  return (
    <div>
      <Header />
      <div className='container-lg'>
        <div className='adminActions'>
          <div className='aside'>
            <h3 className='fs-5 pt-2 pb-2'>Select an Item To Manage</h3>
            <ul>
              {buttons.map(button =>
                <motion.li
                  key={button.name}
                  initial={{ y: 10 }}
                  animate={{ y: 0 }}
                  whileHover={{ scale: 1.01 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={() => sethandleView(button.name)}>
                  <span class="material-icons">{button.icon}</span> {button.name}
                </motion.li>
              )}
            </ul>
          </div>
          <div>
            <h3 style={{ color: "teal", borderLeft: "5px solid teal", paddingLeft: "20px" }} className='mt-2 fs-5 mb-4'>Viewing {handleView}</h3>
            {handleView === "Event Categories" && <CategoryComponent />}
            {handleView === "Currencies" && <CurrencyComponent />}
            {handleView === "Ticket Type" && <TicketTypeComponent />}
            {handleView === "Ticket Category" && <TicketCategoryComponent />}
            {handleView === "How It Works" && <HowItWorksComponent />}
            {handleView === "Feedback" && <FeedbackComponent />}
            {handleView === "Contact" && <ContactComponent />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Admin