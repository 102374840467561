import React, { useState, useEffect } from 'react'
import Header from './Header'
import Navigator from './Navigator'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom';
import { Card } from './Entry';
import "../css/EventDetails.css"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import useApiRequest from './useApiRequest';
import { useLoadingContext } from './LoadingContext';
import { domain } from './Domain';
import {motion, AnimatePresence} from "framer-motion"

const CategorySelect = () => {
    return (
        <div>
            <span className='text-muted'>March 9, 2023, midnight</span>
            <h5 className='lead fs-6 fw-bolder pt-1 pb-1'>Please select a ticket Type</h5>
            <div className='d-flex justify-content-between border p-2 rounded'>
                <p>Tourism and Experience @ 120,000.0</p>
                <div className='d-flex align-items-center gap-2'>
                    <p>12 Tickets Left</p>
                    <Link to={'/ticket-purchase'} className='btn btn-success'>Select Category</Link>
                </div>
            </div>
        </div>
    )
}
const EventPreview = ({ completed, regData, handleApprove }) => {
    const { loading, setLoading } = useLoadingContext()
    const { getRequest } = useApiRequest()
    const [eventDetails, setEventDetails] = useState(null)
    const [ticketDetails, setTicketDetails] = useState(null)
    const [cheapestTicket, setCheapestTicket] = useState(null);
    const [highestTicket, setHighestTicket] = useState(null);

    const filterTickets = (tickets) => {
        if (!tickets || tickets.length === 0) return;

        const cheapest = tickets.reduce((prev, curr) => {
            return (prev.ticket_price < curr.ticket_price) ? prev : curr;
        });

        const highest = tickets.reduce((prev, curr) => {
            return (prev.ticket_price > curr.ticket_price) ? prev : curr;
        });

        setCheapestTicket(cheapest);
        setHighestTicket(highest);
    };

    const fetchTicketDetails = async () => {
        const details = await getRequest(`${domain}/get-tickets?slug=${regData}`)

        setTicketDetails(details)
        filterTickets(details);

    }


    const fetchEventDetails = async () => {
        const details = await getRequest(`${domain}/event-details/${regData}`)
        setEventDetails(details.details)
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            if (!regData) {
                setLoading(false);
                return;
            }

            try {
                // Perform all fetch requests in parallel and wait for all of them to complete
                await Promise.all([fetchEventDetails(), fetchTicketDetails()]);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                // Set loading to false when all requests are completed (or error occurs)
                setLoading(false);
            }
        };

        fetchData();
    }, [regData])

    // Function to format the date to 'August 3, 2023'
    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [randomDateTime, setRandomDateTime] = useState('');
    const [timeCategory, setTimeCategory] = useState('');

    const generateRandomDateAndCategory = () => {
        // Generate a random date and time
        const randomDate = new Date(
            Date.now() - Math.floor(Math.random() * 10000000000) // Random offset in milliseconds
        );

        const options = { year: 'numeric', month: 'long', day: 'numeric' };

        // Get the hour of the day (0-23)
        const hour = randomDate.getHours();

        // Determine the time category based on the hour
        let category = '';
        if (hour >= 6 && hour < 12) {
            category = 'Morning';
        } else if (hour >= 12 && hour < 18) {
            category = 'Afternoon';
        } else {
            category = 'Evening';
        }

        // Update the state with the random date and category
        setRandomDateTime(randomDate.toLocaleDateString('en-US', options));
        setTimeCategory(category);
    };

    // Generate a random date and category on component mount
    useEffect(() => {
        generateRandomDateAndCategory();
    }, []);

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            // Check if the user has scrolled more than 100px from the top
            if (window.scrollY > 50) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        // Attach the event listener for scrolling
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <h6 className='d-block d-md-none'>Event Preview</h6>
            {!eventDetails ?
                <div className='animate__animated animate__fadeIn'>
                    <div className='details'>
                        <div className='detailsIntroduction'>
                            <img className='w-100' src='https://img.freepik.com/free-photo/new-business-ribbon-cutting-celebration-event-concept_53876-124065.jpg?size=626&ext=jpg&ga=GA1.1.967060102.1696464000&semt=ais' />
                            <div className='d-none'>
                                <p>Food and Drink</p>
                                <h3>The Fabulous Brunch at Four Points by Sheraton</h3>
                                <p>Event By: </p>
                            </div>
                        </div>
                        <div className='row container pt-4 m-auto'>
                            <div className='col-lg-8'>
                                <div>
                                    <h3>Food and Drink</h3>
                                    <p>Event By: Organiser Name</p>
                                    <h2 class="fs-5">About</h2>
                                    <p>This section contains all the important details about the upcoming event. Here, you will find information such as the event schedule, speakers, venue, and more once the event has been created! </p>
                                </div>
                                <div>
                                    <h2 class="fs-5">Age Restriction</h2>
                                    <p>Age restriction e.g. Below 18</p>
                                    <h2 class="fs-5">Organiser</h2>
                                    <p>Organiser Name here</p>
                                    <h2 class="fs-5">Refund Policy</h2>
                                    <p>Refund policy here</p>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <h2 class="fs-5"><i class="fa fa-calendar" aria-hidden="true"></i> Date and Time</h2>
                                            <p>Event date here</p>
                                        </div>
                                        <div class="col-lg-6">
                                            <h2 class="fs-5"><i class="fa-solid fa-location-dot"></i> Location</h2>
                                            <p>event location here</p>
                                        </div>
                                    </div>
                                    <iframe
                                        width="100%"
                                        height="300px"
                                        frameborder="0" style={{ border: 0 }}
                                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50
                                &q=kampala,kampala university masaka campus" allowfullscreen>
                                    </iframe>
                                    <h2 class="fs-5">Share this Event</h2>
                                    <a href="https://www.facebook.com/"><i class="fab fa-whatsapp text-success fs-3 pe-2"></i> </a>
                                    <a href="https://www.facebook.com/"><i class="fab fa-facebook fs-3 pe-2"></i> </a>
                                    <a href="https://www.instagram.com/"><i class="fab fa-instagram text-danger fs-3 pe-2"></i> </a>
                                    <a href="https://twitter.com/"><i class="fab fa-twitter fs-2 pe-2"></i> </a>

                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className="sticky">
                                    <h2 className="fs-5">Ugx {parseInt(Math.random() * 1000)} /=</h2>
                                    <hr />
                                    <p><i className="fa fa-calendar icon" aria-hidden="true"></i> {randomDateTime} | {timeCategory}</p>
                                    <p><i className="fa fa-map-marker icon" aria-hidden="true"></i>  event location </p>
                                    <Link onClick={handleShow}>Proceed to Ticket Purchase</Link>

                                </div>

                                {/*regData && <button disabled={completed} onClick={handleApprove} className='btn btn-danger mt-4 publish'>publish Event</button>*/}

                            </div>
                        </div>
                    </div>
                    <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title className='fw-bolder text-muted fs-5'>Modal heading</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <CategorySelect />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary d-none" onClick={handleClose}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                :
                <div className='details'>
                    <div className='detailsIntroduction'>
                        <img className='w-100' src={eventDetails && domain + eventDetails.banner} />
                        <div className='d-none'>
                            <p>{eventDetails && eventDetails.event_category.name}</p>
                            <h3>{eventDetails && eventDetails.event_plot_name}</h3>
                            <p>Event By: {eventDetails && eventDetails.organiser.organiser_name}</p>
                        </div>
                    </div>
                    <div className='row container detailsInner'>
                        <div className='col-lg-8'>
                            <div>
                                <h3>{eventDetails && eventDetails.event_plot_name}</h3>
                                <p>Event By: {eventDetails && eventDetails.organiser.organiser_name}</p>
                                <h2 class="fs-6">About</h2>
                                <p>{eventDetails && eventDetails.event_description}</p>
                            </div>
                            <div>
                                <h2 class="fs-6">Age Restriction</h2>
                                <p>{eventDetails && eventDetails.age_restriction}</p>
                                <h2 class="fs-6">Organiser</h2>
                                <p>{eventDetails && eventDetails.organiser.organiser_name}</p>
                                <h2 class="fs-6">Refund Policy</h2>
                                <p>{eventDetails && eventDetails.refund_policy}</p>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <h2 class="fs-6"><i class="fa fa-calendar" aria-hidden="true"></i> Date and Time</h2>
                                        <p>{eventDetails && formatDate(new Date(eventDetails.start_date_time))}</p>
                                    </div>
                                    <div class="col-lg-6">
                                        <h2 class="fs-6"><i class="fa-solid fa-location-dot"></i> Location</h2>
                                        <p>Venue: {eventDetails && eventDetails.venue_name}</p>
                                        <p>Address: {eventDetails && eventDetails.venue_address}</p>
                                    </div>
                                </div>
                                <iframe
                                    width="100%"
                                    height="300px"
                                    frameborder="0" style={{ border: 0 }}
                                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50
                                &q=${eventDetails && eventDetails.venue_address}`} allowfullscreen>
                                </iframe>
                                <h2 class="fs-5">Share this Event</h2>
                                <a href="https://www.facebook.com/"><i class="fab fa-whatsapp text-success fs-3 pe-2"></i> </a>
                                <a href="https://www.facebook.com/"><i class="fab fa-facebook fs-3 pe-2"></i> </a>
                                <a href="https://www.instagram.com/"><i class="fab fa-instagram text-danger fs-3 pe-2"></i> </a>
                                <a href="https://twitter.com/"><i class="fab fa-twitter fs-2 pe-2"></i> </a>

                            </div>
                        </div>
                        <div className='col-lg-4 switched'>
                            <div className="sticky">
                                <h6>Tickets Available From </h6>
                                <h2 className="fs-6">{cheapestTicket && `Ugx ${cheapestTicket.ticket_price.toLocaleString()} /= ${highestTicket && ' to Ugx ' + highestTicket.ticket_price.toLocaleString()} /=`}</h2>
                                <hr />
                                <p><i className="fa fa-calendar icon" aria-hidden="true"></i> {eventDetails && formatDate(new Date(eventDetails.start_date_time))}</p>
                                <p><i className="fa fa-map-marker icon" aria-hidden="true"></i>  {eventDetails && eventDetails.venue_name} </p>
                                <Link onClick={handleShow} className='mb-4'>Proceed to Ticket Purchase</Link>
                            </div>
                            <div className='mt-4'>
                                {eventDetails &&
                                    <img className='img-fluid' src={domain + eventDetails.listing_img} />}
                            </div>
                            
                            {/*regData && <button disabled={completed} onClick={handleApprove} className='btn btn-danger mt-4 publish'>publish Event</button>*/}
                            <AnimatePresence>
                                {regData && isVisible &&
                                    <motion.div
                                        initial={{ opacity: 0, x: 100 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: 100 }}
                                        transition={{ duration: 0.3 }}
                                        className='publish'
                                        >
                                        <motion.button
                                            whileHover={{ scale: 1.1 }}    // Scale up on hover
                                            whileTap={{ scale: 0.9 }}      // Scale down when pressed (tap)
                                            transition={{ type: "spring", stiffness: 300 }}
                                            disabled={completed} 
                                            onClick={handleApprove} >Publish Event</motion.button>
                                    </motion.div>
                                }

                            </AnimatePresence>
                        </div>

                    </div>
                </div>
            }
        </>
    )
}

export default EventPreview