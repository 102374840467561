import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const LocationPicker = () => {
  const [position, setPosition] = useState(null);
  const [address, setAddress] = useState(null);

  // Function to reverse geocode the coordinates
  const getAddressFromCoordinates = async (lat, lng) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${lng}&format=json`
    );
    const data = await response.json();
    setAddress(data.display_name); // Set address in the state
  };

  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition({ lat, lng });
        getAddressFromCoordinates(lat, lng); // Fetch the address on click
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>
          Latitude: {position.lat} <br /> Longitude: {position.lng} <br />
          Address: {address || "Fetching..."}
        </Popup>
      </Marker>
    );
  }

  return (
    <div style={{ height: "500px" }}>
      <MapContainer center={[0.3323642, 32.5701759]} zoom={15} style={{ height: "100%" }}>
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
      {position && (
        <div>
          <h3>Selected Location:</h3>
          <p>Latitude: {position.lat}</p>
          <p>Longitude: {position.lng}</p>
          <p>Address: {address || "Fetching address..."}</p>
        </div>
      )}
    </div>
  );
};


export default LocationPicker;


/*
import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

const LocationPicker = () => {
  // State to store latitude and longitude of the selected location
  const [position, setPosition] = useState(null);

  // Custom Hook to handle map events like click
  function LocationMarker() {
    const map = useMapEvents({
      click(e) {
        const { lat, lng } = e.latlng;
        setPosition({ lat, lng });
      },
    });

    return position === null ? null : (
      <Marker position={position}>
        <Popup>
          Latitude: {position.lat} <br /> Longitude: {position.lng}
        </Popup>
      </Marker>
    );
  }

  return (
    <div style={{ height: "500px" }}>
      <MapContainer center={[51.505, -0.09]} zoom={13} style={{ height: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker />
      </MapContainer>
      {position && (
        <div>
          <h3>Selected Location:</h3>
          <p>Latitude: {position.lat}</p>
          <p>Longitude: {position.lng}</p>
        </div>
      )}
    </div>
  );
};

export default LocationPicker;
 */