import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../css/Faq.css'; // For additional styling
import Header from './Header';
import Footer from './Footer';

const faqs = [
    {
        question: "What is EventPlots?",
        answer: "EventPlots is an online ticketing platform based in Uganda that allows event organizers to create and promote their events, manage ticket sales, and collect payments all in one place, while providing ticket buyers with a convenient and secure way to purchase tickets for their favorite events.",
    },
    {
        question: "How does EventPlots work?",
        answer: "Event organizers can sign up for a free account, create and customize their event page, set ticket prices and availability, and manage ticket sales and refunds. Ticket buyers can browse events on the platform, purchase tickets securely using a variety of payment methods, and receive their tickets via email or SMS.",
    },
    {
        question: "Is EventPlots safe and secure?",
        answer: "Yes, EventPlots uses industry-standard security protocols to protect your personal and financial information. We also provide secure payment options, including credit and debit card payments, mobile money payments, and bank transfers.",
    },
    {
        question: "What types of events can I find on EventPlots?",
        answer: "EventPlots features a wide variety of events, including music concerts, sporting events, cultural festivals, business conferences, and more.",
    },
    {
        question: "How do I contact EventPlots customer support?",
        answer: "You can contact EventPlots customer support via email, phone, or live chat on our website. Our team is available to assist you with any questions or concerns you may have.",
    },
    {
        question: "How can I partner with EventPlots?",
        answer: "If you are an event organizer or a sponsor looking to partner with EventPlots, please contact us via email or phone to discuss potential partnership opportunities.",
    },
    {
        question: "What is EventPlots' refund policy?",
        answer: "Refund policies are determined by the event organizer and may vary depending on the event. If you have any questions or concerns about a refund, please contact EventPlots customer support.",
    },
    {
        question: "Does EventPlots charge a fee for ticket sales?",
        answer: "Yes, EventPlots charges a fee for each ticket sold on our platform. The fee amount may vary depending on the event and the payment method used.",
    },
    {
        question: "How do I create an account on EventPlots?",
        answer: "To create an account on EventPlots, simply go to our website and click on the 'Sign Up' button. You can create an account using your email address or social media account.",
    },
    {
        question: "Can I sell tickets for free events on EventPlots?",
        answer: "Yes, you can sell tickets for free events on EventPlots. However, there may still be fees associated with the payment method used.",
    },
    {
        question: "How do I transfer or resell my EventPlots ticket?",
        answer: "Transferring or reselling EventPlots tickets is at the discretion of the event organizer. Please contact the event organizer for instructions on how to transfer or resell your ticket.",
    },
    {
        question: "What happens if an event is cancelled or postponed?",
        answer: "If an event is cancelled or postponed, EventPlots will provide instructions on how to obtain a refund or exchange for your ticket. The refund or exchange policy may vary depending on the event and the event organizer. All refunds will be made by the event organizer.",
    },
    {
        question: "What payment methods are accepted on EventPlots?",
        answer: "EventPlots accepts a variety of payment methods, including credit and debit card payments and mobile money payments. The available payment methods may vary depending on the event.",
    },
    {
        question: "How do I download my EventPlots ticket?",
        answer: "Once you purchase a ticket on EventPlots, you will receive an email or SMS with instructions on how to download and print your ticket. You can also access your ticket by logging into your EventPlots account and going to the 'My Tickets' section. EventPlots also sends you a downloadable link for your ticket.",
    },
    {
        question: "What should I do if I have trouble purchasing a ticket on EventPlots?",
        answer: "If you experience any issues while purchasing a ticket on EventPlots, please contact our customer support team for assistance. We will do our best to resolve any issues as quickly as possible.",
    },
];


const Faq = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleFAQ = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <>
            <Header />
            <div className="faq-container">
                <h1 className="faq-title">Frequently Asked Questions</h1>
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <motion.div
                            className="faq-question"
                            onClick={() => toggleFAQ(index)}
                            whileHover={{ scale: 1.01 }}
                        >
                            <span class="material-icons qtn">help</span> <p className='p-0 m-0'>{faq.question}</p>
                        </motion.div>
                        <AnimatePresence>
                            {openIndex === index && (
                                <motion.div
                                    className="faq-answer"
                                    initial={{ opacity: 0, height: 0 }}
                                    animate={{ opacity: 1, height: 'auto' }}
                                    exit={{ opacity: 0, height: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    {faq.answer}
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                ))}
            </div>
            <Footer />
        </>
    );
};

export default Faq;
