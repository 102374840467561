import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import { domain } from './Domain';
import useFetch from './useFetch';
import useApiRequest from './useApiRequest';
import { useLoadingContext } from './LoadingContext';
import notify from './Nots';
import "../css/MyPaymentRequests.css"

const MyPaymentRequests = () => {
    const { setLoading } = useLoadingContext()
    const { deleteRequest, postRequest } = useApiRequest()
    const { data, loading, setLoading:setLoadingData, refresh, setRefresh } = useFetch(`${domain}/payment-requests`)

    useEffect(()=>{
        setLoading(loading)
    },[loading])

    // Handle deleting a payment request
    const handleDelete = async(id) => {
        if(window.confirm("Do you want to delete this request ?")){
            const response = await deleteRequest(`${domain}/payment-requests/${id}`)
            setRefresh(!refresh)
        }else{
            notify("Deletion Canceled", "info")
        }
        
    };

    function formatDate(date) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }

    const handleAction = async(e, method) => {
        const formData = new FormData()
        formData.append("id", e)
        formData.append("action", method)
        const res = await postRequest(`${domain}/approve`, formData)
        if(res){
            notify(JSON.stringify(res['message']), res['status'] === "success" ? "info" : "error")
            setRefresh(!refresh)
        }
    }
    return (
        <div>
            <Header />
            <div className='container-lg'>
                <h2 className='fs-4 pt-4 pb-4'>Payment Requests</h2>
                <div class="table-responsive">
                    <table >
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Event</th>
                                <th>Amount Requested</th>
                                <th>Total Collected</th>
                                <th>Request Date</th>
                                <th>Status</th>
                                <th>Actions</th>
                                <th>Approve / Deny</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.map((request, index) => (
                                <tr key={request.id}>
                                    <td>{index + 1}</td>
                                    <td>{request.event.event_plot_name}</td>
                                    <td>${request.amount.toFixed(2)}</td>
                                    <td>${request.total_available.toFixed(2)}</td>
                                    <td>{formatDate(new Date(request.created_at))}</td>
                                    <td>{request.status ? "Approved":"Pending Approval"}</td>
                                    <td
                                        onClick={() =>
                                            request.status === false ? handleDelete(request.id) : null
                                        }
                                        style={{
                                            cursor: request.status === false ? "pointer" : "not-allowed",
                                            backgroundColor: request.status === "Pending" ? "#f8d7da" : "#f8f9fa",
                                            textAlign: "center",
                                        }}
                                    >
                                        {request.status === false ? "Delete" : "Not Allowed"}
                                    </td>
                                    <td className='d-flex m-0 p-0'>
                                        <button className='btn btn-success rounded-0 flex-grow-1 p-2 border-0' onClick={() => handleAction(request.id, "approve")}>approve</button>
                                        <button className='btn btn-danger rounded-0 flex-grow-1 border-0' onClick={() => handleAction(request.id, "cancel")}>cancel</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MyPaymentRequests