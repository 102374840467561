import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import Entry from './components/Entry';
import Login from './components/Login';
import Register from './components/Register';
import CreatePlot from './components/CreatePlot';
import HowItWorks from './components/HowItWorks';
import HelpCenter from './components/HelpCenter';
import Search from './components/Search';
import SingleCategory from './components/SingleCategory';
import EventDetails from './components/EventDetails';
import ForgotPassword from './components/ForgotPassword';
import 'animate.css';
import TicketPurchase from './components/TicketPurchase';
import { useLoadingContext } from './components/LoadingContext';
import Loader from './components/Loader';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import HelpCenterInfo from './components/HelpCenterInfo';
import HowItWorksDetails from './components/HowItWorksDetails';
import PasswordResetForm from './components/PasswordResetForm';
import MyEvents from './components/MyEvents';
import UserProfile from './components/UserProfile';
import RevenueDashboard from './components/RevenueDashboard';
import RouteLogger from './components/RouterLogger';
import UrlProvider from './components/RouterLogger';
import MyPaymentRequests from './components/MyPaymentRequests';
import Admin from './components/Admin';
import ScrollToTop from './components/ScrollToTop';
import Activation from './components/Activation';
import NotFoundPage from './components/NotFoundPage';
import Faq from './components/Faq';



function App() {
  const { loading } = useLoadingContext()
  return (
    <UrlProvider>
    <div className="App">
      <ScrollToTop />
      <RouteLogger />
      <ToastContainer />
      {loading && <Loader />}
      <Routes>
        <Route path='/' element={<Entry />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/accounts/activation' element={<Activation />} />
        <Route path='/forgot-password' element={<ForgotPassword />}/>
        <Route path='/accounts/password-reset/:slug' element={<PasswordResetForm />} />
        <Route path='/accounts/profile' element={<UserProfile />} />
        <Route path='/register' element={<Register />}/>
        <Route path='/create-plot' element={<CreatePlot />}/>
        <Route path='/frequently-asked-questions' element={<Faq />}/>
        <Route path='/how-it-works' element={<HowItWorks />}/>
        <Route path='/how-it-works/:name' element={<HowItWorksDetails />}/>
        <Route path='/help-center' element={<HelpCenter />}/>
        <Route path='/help-center/:name' element={<HelpCenterInfo />}/>
        <Route path='/search' element={<Search />}/>
        <Route path='/categories/:name' element={<SingleCategory />}/>
        <Route path='/events/:name' element={<EventDetails />}/>
        <Route path='/ticket-purchase' element={<TicketPurchase />}/>
        <Route path='/my-events' element={<MyEvents />}/>
        <Route path='/my-revenue' element={<RevenueDashboard />}/>
        <Route path='/my-payment-requests' element={<MyPaymentRequests />}/>
        <Route path='/admin' element={<Admin />}/>
        <Route path="*" element={< NotFoundPage />} />
      </Routes>
    </div>
    </UrlProvider>
  );
}

export default App;
