import React, { useState } from 'react';
import '../css/Likes.css'; // Import the CSS for styling
import { domain } from './Domain'
import useApiRequest from './useApiRequest'

const Likes = ({ slug }) => {
    const { getRequest } = useApiRequest()
    const [liked, setLiked] = useState(false);

    const toggleLike = async() => {
        if(liked !== true){
            const response = await getRequest(`${domain}/event-details?likes=likes&slug=${slug}`)
            if(response){
                setLiked(!liked);
            }
        }
        
    };

    return (
        <button className="bg-transparent border-0 d-flex align-items-center gap-2 fw-bold" onClick={toggleLike}>
            <span className={`material-icons heart ${liked ? 'glow' : ''}`}>favorite</span> {liked ? <span style={{textShadow: "1px 1px white"}} className='text-danger text'>Event Liked</span> : <span className='text-white text'>Like Event</span>}
        </button>
    );
};

export default Likes;
