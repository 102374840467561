import React from 'react';
import { Helmet } from 'react-helmet';

const MetaHelmet = ({ title, description, imageUrl, url }) => {
    return (
        <Helmet>
            {/* Document Title */}
            <title>{title || 'Default Title'}</title>

            {/* Open Graph Meta Tags (For Facebook and LinkedIn) */}
            <meta property="og:type" content="website" />
            <meta property="og:title" content={title || 'Default Title'} />
            <meta property="og:description" content={description || 'EventPlots is Uganda’s premier ticketing platform, offering seamless event booking, management, and discovery. Enjoy a user-friendly, secure, and reliable experience tailored to meet all your event needs effortless'} />
            <meta property="og:image" content={imageUrl || 'https://eventplots.com/images/logo.png'} />
            <meta property="og:url" content={url || 'https://eventplots.com'} />
            <meta property="og:site_name" content="Your Website" />

            {/* Twitter Card Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title || 'Default Title'} />
            <meta name="twitter:description" content={description || 'EventPlots is Uganda’s premier ticketing platform, offering seamless event booking, management, and discovery. Enjoy a user-friendly, secure, and reliable experience tailored to meet all your event needs effortless'} />
            <meta name="twitter:image" content={imageUrl || 'https://eventplots.com/images/logo.png'} />
            <meta name="twitter:url" content={url || 'https://eventplots.com'} />
            <meta name="twitter:creator" content="@yourTwitterHandle" />

            {/* General Meta Tags */}
            <meta name="description" content={description || 'EventPlots is Uganda’s premier ticketing platform, offering seamless event booking, management, and discovery. Enjoy a user-friendly, secure, and reliable experience tailored to meet all your event needs effortless'} />
            <link rel="canonical" href={url || 'https://eventplots.com'} />
        </Helmet>
    );
};

export default MetaHelmet;
