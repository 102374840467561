import React, {useEffect, useState, useRef} from 'react'
import useApiRequest from './useApiRequest'
import { domain } from './Domain'
import { motion, AnimatePresence } from 'framer-motion'
import "../css/Activation.css"
import { useNavigate } from 'react-router-dom'

const Activation = () => {
  const navigate = useNavigate()
  const { getRequest, response } = useApiRequest()
  const [success, setSuccess] = useState(false)
  const [count, setCount] = useState(6)
  let interval
  

  const check = async(token) => {
    const response = await getRequest(`${domain}/accounts/activation?token=${token}`)
    if(response['message'] === "Acount Activation Successfull!"){
      setSuccess(true)
      interval = setInterval(() => {
        setCount(prevCount => prevCount - 1); // Update count correctly
      }, 1000);

      setTimeout(()=>{
        navigate("/login")
      },3000)
    }
  }
  useEffect(()=>{
    const queryParams = new URLSearchParams(window.location.search);
    const tokenParam = queryParams.get('token');
    check(tokenParam)

    // Clean up function to clear interval when component unmounts
    return () => {
      clearInterval(interval);
    };
  },[])
  return (
    <div className='activation'>
      <div className='inner'>
        <h1>Your Account is getting Activated <br />Please Wait</h1>
        <div class="lds-dual-ring"></div>
        <AnimatePresence>
          {success && 
            <motion.p initial={{scale: 0, opacity: 0}} animate={{scale:1, opacity: 1}} exit={{scale: 0, opacity: 0}} key={success} className='text-success fw-bold text-center mt-4'>Account activation successfull<br />redirecting in {count} seconds ....</motion.p>
          }
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Activation