import React, { useState, useRef, useEffect } from 'react'
import Header from './Header'
import '../css/MyEvents.css';
import { domain } from './Domain';
import useFetch from './useFetch';
import useApiRequest from './useApiRequest';
import { useNavigate } from 'react-router-dom';
import { useLoadingContext } from './LoadingContext';
import { saveToLocalStorage } from './EncryptDecrypt';
import notify from './Nots';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const EventList = ({ dataProfiles, events, onEdit, onDelete }) => {
    const [loaded, setLoaded] = useState(false);
    const truncateString = (str) => {
        if (str.length <= 90) return str;

        // Truncate the string to 90 characters
        let truncated = str.substring(0, 90);

        // Find the last space in the truncated string
        const lastSpaceIndex = truncated.lastIndexOf(' ');

        // If there's a space, truncate to that index; otherwise, keep it as is
        if (lastSpaceIndex > -1) {
            truncated = truncated.substring(0, lastSpaceIndex);
        }

        return truncated + " ...";
    };

    const handleImageLoad = () => {
        setLoaded(true);
    };

    return (
        <div className="event-list">
            {dataProfiles && events && dataProfiles.map((event, index) => {
                const detailed = events.find(item => item.organiser.id === event.id);
                return (
                    detailed ? <div key={index} className="event-card">
                        <div className='position-relative'>
                            {loaded === false && <Skeleton height={250} count={1} />}
                            
                            <img 
                                src={domain + detailed.banner} 
                                alt={detailed.event_plot_name} 
                                className="event-cover"
                                onLoad={handleImageLoad}
                                style={{ display: loaded ? 'block' : 'none' }} />
                            <p className="event-location">{detailed.venue_name}</p>
                        </div>
                        <h3 className="event-title">{detailed.event_plot_name}</h3>
                        <p className="event-description">{truncateString(detailed.event_description)}</p>

                        {/*<p className="event-price">${detailed.event_capacity}</p>*/}
                        {detailed.organiser.status ? (
                            <span className="event-status completed">Completed</span>
                        ) : (
                            <span className="event-status not-completed">Not Completed</span>
                        )}
                        <div className="event-actions">
                            <button className="btn btn-success" onClick={() => onEdit(detailed)}><i class="fa-solid fa-pen-to-square"></i> Edit</button>
                            <button className='btn btn-danger' onClick={() => onDelete(detailed)}><i class="fa-solid fa-trash"></i> Delete</button>
                        </div>
                    </div>
                        :
                        <div key={index} className="missing-info event-card">
                            <img src="https://th.bing.com/th/id/R.db0c9ce9132f40034d95bcaa1e5341cf?rik=Vc7zQVdlXC3kbg&pid=ImgRaw&r=0&sres=1&sresct=1" alt={event.organiser_name} className="event-cover" />
                            <h3>No details found</h3>
                            {event.status ? (
                                <span className="event-status completed">Completed</span>
                            ) : (
                                <span className="event-status not-completed">Not Completed</span>
                            )}
                            <p className="event-description"><q>Important details for this event are missing. Please review and update the necessary information to ensure a better experience for everyone.</q></p>
                            <p className="event-location">Thank You</p>
                            <div className="event-actions">
                                <button onClick={() => onEdit(event)}><i class="fa-solid fa-pen-to-square"></i> Edit</button>
                                <button onClick={() => onDelete(detailed)}><i class="fa-solid fa-trash"></i> Delete</button>
                            </div>
                        </div>
                );
            })}
        </div>
    );
};

const MyEvents = () => {
    const navigate = useNavigate()
    const { deleteRequest } = useApiRequest()
    const { data: dataEvents, loading: loadingEvents, setLoading: setLoadingEvents, refresh, setRefresh } = useFetch(`${domain}/event-details`)
    const { data: dataProfiles, loading: loadingProfiles, setLoading: setLoadingProfiles, refresh: refreshProfiles, setRefresh: setRefreshProfiles } = useFetch(`${domain}/my-events`)
    const { loading, setLoading } = useLoadingContext()

    useEffect(() => {
        if (loadingEvents && loadingProfiles) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [loadingEvents, loadingProfiles])

    const handleEdit = async (event) => {
        localStorage.setItem("edit", true);
        console.log('Edit event:', event);
        // Implement edit logic here
        saveToLocalStorage("eventReg", event.slug);
        navigate("/create-plot")
    };

    const handleDelete = async (event) => {
        //setEvents(events.filter(event => event.id !== id));
        if (window.confirm("Do you want to delete this event ?")) {
            setLoading(true);
            const res = await deleteRequest(`${domain}/event-details/${event.slug}`);
            if (res) {
                setRefresh(!refresh);
                setRefreshProfiles(!refreshProfiles);
                setLoading(false)
                notify("Event Deletion Successfull", "info");
            }
        } else {
            notify("Event Deletion Canceled", "info")
        }
    };

    return (
        <div>
            <Header />
            <div className='container-lg'>
                <h2 className='fs-4 pt-4'>My Events</h2>

                {dataProfiles ?
                    <EventList dataProfiles={dataProfiles} events={dataEvents} onEdit={handleEdit} onDelete={handleDelete} />
                    :
                    <div>
                        <h3>Events are Loading ...</h3>
                        <p>kindly wait ...</p>
                    </div>
                }
            </div>
        </div>
    )
}



export default MyEvents